import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@app/app.config';

import { HttpService } from '../utils/http.service';
import { ContactValidationPublicConfirmationDTO } from '../../models/dtos/generated/contact-validation-public-confirmation-dto';

@Injectable()
export class GameOfficialConfirmationDataService {
    protected GameOfficialApiEndpoint:string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.GameOfficialApiEndpoint = config.gameOfficialApiEndpoint;
    }

    public getFetchObservable(guid: string): Observable<ContactValidationPublicConfirmationDTO> {
        return this.http.get(this.GameOfficialApiEndpoint + "/contact-confirmation/" + guid);
    }

    public getPutObservable(model: ContactValidationPublicConfirmationDTO):Observable<boolean> {
        return this.http.put(this.GameOfficialApiEndpoint + "/contact-confirmation", model);
    }

}
