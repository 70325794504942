export class EntityTypes {
    static readonly PERSONS = 'Persons';
    static readonly PERSON_NATIONALITIES = 'PersonNationalities';
    static readonly TRANSFERS = 'Transfers';
    static readonly PLAYER_LICENSES = 'PlayerLicenses';
    static readonly PLAYER_RELEASE = 'PlayerRelease';
    static readonly BASKETBALL_LICENSES = 'BasketballLicenses';
    static readonly COACH_LICENSES = 'CoachLicenses';
    static readonly LICENSE_REQUEST = 'LicenseRequests';
    static readonly SANCTION_APPLICATIONS = 'SanctionApplications';
    static readonly VENUES = 'Venues';
    static readonly ORGANISATIONS = 'Organisations';
    static readonly LONG_LIST_MEMBERS = 'LongListMembers';
    static readonly COMPETITION = 'Competition';
    static readonly COMPETITION_TEAMS = 'CompetitionTeams';
    static readonly OFFICIAL_LICENSE_PERIODS = 'OfficialLicensePeriods';
    static readonly AGENT_REGISTRATIONS = 'AgentRegistrations';
}