import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { FibaPopupBase } from '@fiba/forms';
import { DocumentGenericDTO, AgentRegistrationFormDTO } from '@fiba/models';
import { AppConfig } from '@app/app.config';
import { ErrorEvent, FileRestrictions, UploadEvent, SuccessEvent } from '@progress/kendo-angular-upload';

@Component({
    selector: 'fibaPopupUploadAgentRegistrationDocument',
    templateUrl: './fiba-popup-document-agent-registration.component.html',
})
export class FibaPopupUploadAgentRegistrationDocumentComponent extends FibaPopupBase<DocumentGenericDTO> {
    @Output() public fileUploaded: EventEmitter<DocumentGenericDTO> = new EventEmitter();
    @Input() public agentRegistrationForm: AgentRegistrationFormDTO;
    @Input() public guid: string = null;
    @Input() public disabled: boolean = true;
    public uploadSaveUrl: string;
    public uploadInProgress: boolean = false;
    public errorMessage: string;

    protected saveMethod: string = 'POST';
    private readonly agentRegistrationApiEndpoint: string;
    private uploadFinished: boolean = false;
    private uploadRoute: string = 'upload-agent-registration-document';

    public _restrictions: FileRestrictions = {
        maxFileSize: 10000000,
        allowedExtensions: ['pdf'],
    };

    constructor(protected injector: Injector, protected config: AppConfig) {
        super(injector);
        this.agentRegistrationApiEndpoint = config.agentRegistrationApiEndpoint;
    }

    public open() {
        this.active = true;
        this.errorMessage = null;
        this.uploadFinished = false;
        this.uploadSaveUrl = `${this.agentRegistrationApiEndpoint}/${this.agentRegistrationForm.clientGuid}/upload-registration-document`;
    }

    public successEventHandler(event: SuccessEvent) {
        var documentGenericDTO = event.response.body as DocumentGenericDTO;
        this.fileUploaded.emit(documentGenericDTO);
        this.active = false;
    }

    public errorEventHandler(event: ErrorEvent) {
        this.errorMessage = (event.response as any).error; // TODO: Weird fix CEDRIC
    }

    public cancelEventHandler(event) {
        this.errorMessage = null;
        this.uploadFinished = true;
        this.uploadInProgress = false;
    }

    public clearEventHandler(event) {
        this.errorMessage = null;
        this.uploadFinished = true;
        this.uploadInProgress = false;
    }

    public completeEventHandler(event) {
        this.uploadFinished = true;
        this.uploadInProgress = false;
    }

    // noinspection JSUnusedGlobalSymbols
    public uploadEventHandler(e: UploadEvent) {
        // Add post parameter gameActionsOnly to post payload
        e.data = {
            password: this.agentRegistrationForm.password
        };
        this.uploadInProgress = true;
    }

    protected closeForm(): void {
        if (this.uploadInProgress == false) {
            this.active = false;
            this.cancel.emit();

            if (this.uploadFinished) {
                // Trigger reload of the model in the parent
                this.fileUploaded.emit(null);
            }
        }
    }
}
