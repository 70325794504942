import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'fibaCheckboxThreeState',
    templateUrl: './fiba-checkbox-three-state.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaCheckboxThreeStateComponent),
            multi: true,
        },
    ],
    host: {class: 'fiba-input'},
})
export class FibaCheckboxThreeStateComponent implements ControlValueAccessor {

    @Input() public label:string; // Text to display in the label
    @Input() public name:string; // HTML id and name to give to the input
    @ViewChild('checkbox') private _checkbox:ElementRef;

    private _checked:boolean = null;

    get checked() {
        return this._checked;
    }

    set checked(checked:boolean) {
        this._checked = checked;
        this.render();
        this.onChangeCallback(this._checked);
    }

    public writeValue(value:any):void {
        if (value !== undefined) {
            this.checked = value;
        }
    }

    public registerOnChange(fn:(_:any) => void):void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn:() => void):void {
        this.onTouchedCallback = fn;
    }

    public onChange():void {
        this.onTouchedCallback();
    }

    public setState():void {
        if (this._checkbox.nativeElement.readOnly) {
            this._checkbox.nativeElement.checked = this._checkbox.nativeElement.readOnly = this.checked = false;
        } else if (!this._checkbox.nativeElement.checked) {
            this._checkbox.nativeElement.readOnly = this._checkbox.nativeElement.indeterminate = true;
            this.checked = null;
        } else {
            this.checked = true;
        }
    }

    private onChangeCallback = (_:any) => {
    };
    private onTouchedCallback = () => {
    };

    private render():void {
        switch (this.checked) {
            case true:
                this._checkbox.nativeElement.readOnly = this._checkbox.nativeElement.indeterminate = false;
                this._checkbox.nativeElement.checked = true;
                break;
            case false:
                this._checkbox.nativeElement.checked = this._checkbox.nativeElement.readOnly = this._checkbox.nativeElement.indeterminate = false;
                break;
            case null:
            case undefined:
            default:
                this._checkbox.nativeElement.readOnly = this._checkbox.nativeElement.indeterminate = true;
                break;
        }
    }
}
