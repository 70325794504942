<label *ngIf="label && label.length > 0" for="{{name}}">{{label}}</label>
<div>
    <div class="horizontal space10">
        <kendo-datepicker (valueChange)="handleDateChange($event)"
                          [(ngModel)]="date"
                          [disabled]="disabledDate || disabled"
                          [format]="'dd-MMM-yyyy'"
                          [required]="required"
                          class="date"
                          id="{{name}}"></kendo-datepicker>
        <span (click)="addTime()" *ngIf="!hasTime && canSetHasTime" class="add-time-button"
              style="display: inline-block; cursor: pointer;">
            <i class="fa fa-clock-o"></i>
        </span>
        <span>
            <kendo-dateinput (blur)="handleBlur()"
                             (valueChange)="handleTimeChange($event)"
                             *ngIf="hasTime"
                             [(ngModel)]="time"
                             [disabled]="disabledTime || disabled"
                             [format]="'HH:mm'"
                             [required]="required"
                             class="time"></kendo-dateinput>
            <span (click)="removeTime()" *ngIf="hasTime && canSetHasTime" class="remove-time-button"
                  style="display: inline-block; cursor: pointer;">
                <i class="fa fa-times"></i>
            </span>
        </span>
    </div>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                &nbsp;{{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.fibaGreaterValidator">
                Start date must be lower than end date
            </li>
        </ul>
    </div>
</div>

