import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigurationDocumentDTO, DocumentStatus } from '@fiba/models';
import { AppConfig } from '@app/app.config';

import { HttpService, IFileData } from '../utils/http.service';

@Injectable()
export class DocumentStatusDataService {

    public readonly documentStatusApiEndpoint: string;

    public get DocumentStatusCodes_Validated(): string {
        return 'VALID';  // TODO : !! Refactor verbatim constant here (should depend on derived type ? )
    }
    public get DocumentStatusCodes_Submitted(): string {
        return 'SUB';  // TODO : !! Refactor verbatim constant here (should depend on derived type ? )
    }
    public get DocumentStatusCodes_Rejected(): string {
        return 'REJ';  // TODO : !! Refactor verbatim constant here (should depend on derived type ? )
    }
    public get DocumentStatusCodes_Deleted(): string {
        return 'DEL';  // TODO : !! Refactor verbatim constant here (should depend on derived type ? )
    }

    public get ItemDocumentTypeCode(): string { throw new Error('Must be implemented'); }

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.documentStatusApiEndpoint = config.documentApiEndpoint;
    }
    public fetchDocumentStatuses(): Observable<DocumentStatus[]> {
        return this.http.get(`${this.documentStatusApiEndpoint}/${this.ItemDocumentTypeCode}/status`);
    }

    public fetchDocumentConfiguration(): Observable<ConfigurationDocumentDTO[]> {
        return this.http.get(`${this.documentStatusApiEndpoint}/${this.ItemDocumentTypeCode}/configurations`);
    }
}
