import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AgentPreRegistrationFormDTO, AgentTestListItemDTO } from '@fiba/models';
import { AppConfig } from '@app/app.config';

import { HttpService } from '../utils/http.service';

@Injectable()
export class AgentPreRegistrationDataService {
    protected agentPreRegistrationEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.agentPreRegistrationEndpoint = config.agentPreRegistrationApiEndpoint;
    }

    createAgentPreRegistration(model: AgentPreRegistrationFormDTO): Observable<AgentPreRegistrationFormDTO> {
        return this.http.post(`${this.agentPreRegistrationEndpoint}`, model);
    }

    fetchPreRegistrationConfiguration(zoneCode: string): Observable<AgentTestListItemDTO> {
        return this.http.get(`${this.agentPreRegistrationEndpoint}/config/${zoneCode}`);
    }
}
