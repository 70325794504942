import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../app/shared.module';
import { FibaFormsModule } from '../forms/forms.module';
import { FibaSelectItemComponent } from './forms/fiba-select-item.component';
import { FibaSelectItemFilteredComponent } from './forms/fiba-select-item-filtered.component';
import { FibaSelectCountryComponent } from './forms/fiba-select-country.component';

const COMPONENTS: any[] = [
    FibaSelectItemComponent,
    FibaSelectItemFilteredComponent,
    FibaSelectCountryComponent,
];

@NgModule({
    imports: [
        SharedModule,
        FibaFormsModule,
    ],
    declarations: [
        COMPONENTS,
    ],
    exports: [
        COMPONENTS,
    ],
})
export class FibaCommonSelectModule {
}
