import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, AgentTestSeasonDTO, AgentPreRegistrationValidateDTO } from '@fiba/models';
import { AppConfig } from '@app/app.config';

import { HttpService } from '../utils/http.service';
import { DataCachedService } from '../utils/data-cached.service';

import * as Moment from 'moment';
import {map} from 'rxjs/operators';

@Injectable()
export class AgentTestDataService {
    protected agentTestRolesApiEndpoint: string;
    protected agentTestApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.agentTestApiEndpoint = config.agentTestApiEndpoint;
    }

    public fetchAgentTestForSeason(): Observable<AgentTestSeasonDTO> {
        return this.http.get<AgentTestSeasonDTO>(`${this.agentTestApiEndpoint}`).pipe(map(response => {
            const agentTest = response;
            if (agentTest.agentTestListItem) {
                let nowUTC = Moment.utc();
                agentTest.agentTestListItem.map(x => x.cli_isSessionClosed = (Moment(x.deadlineDate).add(x.deadlineUtcOffset, 'hours') < nowUTC));
            }
            return agentTest;
        }));
    }
}
