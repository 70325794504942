import { Directive, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DataStateChangeEvent, GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

@Directive()
export abstract class FibaGSGridBase<T> implements ControlValueAccessor {
    public gridData:GridDataResult;
    public state:State = {};
    @Input() public label:string;
    // Grid editing
    public editDataItem:T;
    public editRowIndex:number;
    protected isNew:boolean = false;
    @Input() protected required:boolean;
    @Input() protected disabled:boolean = false;
    protected mySelection:number[] = [];

    protected _model:T[];

    public get model() {
        return this._model;
    }

    public set model(model:T[]) {
        if (model) {
            this._model = model;
        } else {
            this._model = [];
        }

        this.gridData = process(this._model, this.state);
        this.onModelChanged(); // As of now (TS 2.3), while targetting ES5, we cannot use super.model while in an overridden getter/setter. Override this method instead
    }

    @Input()
    protected set groupByField(value:string) {
        this.state.group = [{field: value}];
    }

    static mySelectionKey(context:RowArgs):number {
        return context.index;
    }

    public dataStateChange(state:DataStateChangeEvent):void {
        this.state = state;
        this.loadGroup();
    }

    public writeValue(model:T[]) {
        this.model = model;
    }

    public onChange = (_:any) => {
    };
    public onTouched:any = () => {
    };

    public registerOnChange(fn):void {
        this.onChange = fn;
    }

    public registerOnTouched(fn:any):void {
        this.onTouched = fn;
    }

    public addHandler($event):void {
        if (!this._model) {
            this._model = [];
        }
        this.editDataItem = this.newModel();
        this.isNew = true;
        this.editRowIndex = this._model.length;
    }

    public editHandler($event):void {
        const {dataItem, rowIndex} = $event;
        this.editDataItem = JSON.parse(JSON.stringify(dataItem));
        this.isNew = false;

        this.editRowIndex = this.getIndex(rowIndex);
    }

    public cancelHandler():void {
        this.editDataItem = undefined;
    }

    public saveHandler(pn:T):void {

        if (this.isNew) {
            this._model.push(pn);
        } else {
            this._model[this.editRowIndex] = pn;
        }
        this.editDataItem = undefined;
        this.onChange(this._model);
        this.loadGroup();
    }

    public removeHandler({rowIndex}):void {
        this._model.splice(this.getIndex(rowIndex), 1);
        this.onChange(this._model);
        this.loadGroup();

    }

    public setDisabledState(isDisabled:boolean):void {
        this.disabled = isDisabled;
    }

    protected onModelChanged():void {
        this.loadGroup();
    }

    protected loadGroup():void {
        this.gridData = process(this._model, this.state);
    }

    protected newModel():T {
        return {} as T;
    }

    private getIndex(rowIndex:number):number {
        const flatGrid:T[] = [];
        this.gridData.data.forEach((i) => {
            i.items.forEach((x) => {
                flatGrid.push(x);
            });
        });

        return this._model.findIndex((x) => x === flatGrid[rowIndex]);
    }
}
