<label *ngIf="label && label.length > 0" for="{{name}}">{{label}}</label>

<div>
    <div class="horizontal space10">
        <kendo-maskedtextbox (valueChange)="handleValue($event)"
                             [(ngModel)]="value"
                             [mask]="mask"
                             [required]="required"
                             class="date"
                             id="{{name}}"></kendo-maskedtextbox>
    </div>
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                &nbsp;{{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.fibaGreaterValidator">
                Start date must be lower than end date
            </li>
        </ul>
    </div>
</div>
