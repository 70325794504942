import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core.module';

import { AppComponent } from './app.component';

@NgModule({
    bootstrap: [AppComponent],
    imports: [
        AppRoutingModule,
        CoreModule,
    ]
})
export class AppModule {
}

// -- Non Angular exports --
export * from './app.config';
