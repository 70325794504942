import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'top-menu',
    templateUrl: './topmenu.component.html',
    styleUrls: ['./topmenu.component.css'],
})
export class TopMenuComponent implements OnInit {
    constructor() {
    }

    public ngOnInit():void {
    }
}
