import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@app/app.config';

import { HttpService } from '../utils/http.service';
import { TableOfficialPublicConfirmationDTO } from '../../models/dtos/generated/table-official-public-confirmation-dto';

@Injectable()
export class TableOfficialEmailConfirmationDataService {
    protected tableOfficialApiEndpoint:string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.tableOfficialApiEndpoint = config.tableOfficialApiEndpoint;
    }

    public getFetchObservable(guid: string): Observable<TableOfficialPublicConfirmationDTO> {
        return this.http.get(this.tableOfficialApiEndpoint + "/email-confirmation/" + guid);
    }

    public getPutObservable(model: TableOfficialPublicConfirmationDTO):Observable<boolean> {
        return this.http.put(this.tableOfficialApiEndpoint + "/email-confirmation/" + model.guid, model);
    }

}
