import { Component, EventEmitter, forwardRef, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';
import { Subject, Subscription } from 'rxjs';
import { Logger } from '@fiba/utils/logger';

@Component({
    selector: 'fibaInputDate',
    templateUrl: './fiba-input-date.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputDateComponent),
            multi: true
        },
    ],
    host: {class: 'fiba-input'},
})
export class FibaInputDateComponent extends FibaInputBase<Date> implements OnInit, OnDestroy {

    @Input() public type = 'filter';
    @Input() public formSaveSuccessEvent:Subject<boolean>;
    @Output() public change:EventEmitter<Date> = new EventEmitter();
    public show = false;
    protected pattern = /^\d{2}\/\d{2}\/\d{4}$/; // TODO: Remove if useless 13/03/18 Alexy
    private subs:Subscription = new Subscription();
    private makePristine:boolean = false;

    constructor(protected injector:Injector) {
        super(injector);
    }

    public _dateToDisplay:Date;

    public get dateToDisplay():Date {
        return this._dateToDisplay;
    }

    public set dateToDisplay(value:Date) {
        if (!value) {
            this.value = null;
        } else {
            this.value = value;
        }
        this._dateToDisplay = value;
    }

    /**
     * Logic is a little complicated, i'll try my best resuming it so nobody gets confused
     * The function handleValue is actually passing back the date to the parent through ngModel (value)
     * When the component is set, value is pre-filled with parent ngModel
     * The value setter (set value() {}) contains an UTC Tricks so everytime the value get out of the component it gets tricked.
     *
     * What is the UTC Tricks
     * The UTC tricks removeUTCOffsetFromDate is needed to remove offset before the data get out BECAUSE
     * after the data get out of the component it get serialized as a Date object and this serialization
     * break the date and add the local utc offset to it, that's why we REMOVE the offset BEFORE sending the data to the parent
     * in order to keep the date unchanged.
     *
     * SO, everytime the datepicker get touched it triggers dateToDisplay and handleValue and apply the tricks logic to value
     *
     */

    public get value():Date {
        return (this._value as Date);
    }

    public set value(value:Date) {
        if (!value) {
            this._value = null;
        } else {
            value = new Date(value); // Force value to be a date, because is a string on first init
            this._value = this.removeUTCOffsetFromDate(value);
        }
        this.handleValue();
        this.makePristineLogic();
        this._dateToDisplay = value;
    }

    public ngOnInit():void {
        super.ngOnInit();
        this.onFormSavedLogic();
    }

    public ngOnDestroy():void {
        this.subs.unsubscribe();
    }

    public handleValue() {
        this.change.emit(this.value as Date);
        this.manualChange();
        this.onTouched();
    }

    private removeUTCOffsetFromDate(date:Date):Date {
        if (date) {
            const userTimezoneOffset = date.getTimezoneOffset() * 60000;
            const newDate = new Date(date.getTime() - userTimezoneOffset);

            return (newDate);
        }
        Logger.error('Invalid date');
    }

    private makePristineLogic():void {
        if (!this._dateToDisplay) {
            this.resetPristineAndTouched();
        }

        if (this.makePristine) {
            this.resetPristineAndTouched();
            this.makePristine = !this.makePristine;
        }
    }

    private resetPristineAndTouched():void {
        if (this.ngControl && this.ngControl.control) {
            this.ngControl.control.markAsPristine();
            this.ngControl.control.markAsUntouched();
        }
    }

    private onFormSavedLogic():void {
        if (this.formSaveSuccessEvent) {
            const subscription:Subscription = this.formSaveSuccessEvent.subscribe(
                () => {
                    this.makePristine = true;
                });

            this.subs.add(subscription);
        }
    }

}
