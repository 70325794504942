import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BreadcrumbService {

    private breadcrumbSource = new Subject<{ level:number, name:string }>();

    breadcrumbSet$ = this.breadcrumbSource.asObservable();

    updateLevel(level:number, name:string) {
        this.breadcrumbSource.next({level, name});
    }
}
