import { NgModule } from '@angular/core';

import { SharedModule } from '../../app/shared.module';

import { TabListComponent } from './components/tablist.component';
import { FibaBackToTopButtonComponent } from './forms/fiba-back-to-top-button.component';
import { CommaSeparatedPipe } from './pipes/commaSeparated.pipe';
import { FibaDatePipe } from './pipes/fibaDate.pipe';
import { FibaDateNoDaysPipe } from './pipes/fibaDateNoDays.pipe';
import { FibaDateTimePipe } from './pipes/fibaDateTime.pipe';
import { FibaUtcDateTimePipe } from './pipes/fibaUtcDateTime.pipe';
import { FibaUtcOffsetPipe } from './pipes/fibaUtcOffset.pipe';
import { FibaGridDocumentsComponent } from './components/documents/fiba-grid-documents.component';
import { FibaPopupDocumentComponent } from './components/documents/fiba-popup-document.component';
import { FibaAutocompleteCityComponent } from './forms/fiba-autocomplete-city.component';
import { FibaInputUploadDocumentComponent } from './forms/fiba-input-upload-document.component';
import { FibaDisplayItemTextComponent } from './components/fiba-display-item-text.component';
import { FibaCheckboxLabelItemComponent } from './forms/fiba-checkbox-label-item.component';
import { FibaFormsModule } from '../forms/forms.module';
import { FibaCommonSelectModule } from './fiba-common-select.module';
import { FibaPopupDocumentRejectComponent } from './components/documents/fiba-popup-document-reject.component';
import { FibaPopupUploadAgentRegistrationDocumentComponent } from './components/documents/fiba-popup-document-agent-registration.component';
import { FibaDisplayItemTextTechnicalDelegateTestComponent } from './components/fiba-display-item-text-technical-delegate-test.component';
import { FibaDisplayItemTextTableOfficialEmailValidationComponent } from './components/fiba-display-item-text-table-official-email-validation.component';

const COMMON_COMPONENTS: any[] = [
    TabListComponent,
    CommaSeparatedPipe,
    FibaDatePipe,
    FibaDateNoDaysPipe,
    FibaDateTimePipe,
    FibaUtcDateTimePipe,
    FibaUtcOffsetPipe,
    FibaBackToTopButtonComponent,
    FibaGridDocumentsComponent,
    FibaPopupDocumentComponent,
    FibaAutocompleteCityComponent,
    FibaInputUploadDocumentComponent,
    FibaDisplayItemTextComponent,
    FibaDisplayItemTextTechnicalDelegateTestComponent,
    FibaDisplayItemTextTableOfficialEmailValidationComponent,
    FibaCheckboxLabelItemComponent,
    FibaPopupDocumentRejectComponent,
    FibaPopupUploadAgentRegistrationDocumentComponent
];

// Components
export { FibaDatePipe } from './pipes/fibaDate.pipe';
export { FibaPopupDocumentRejectComponent } from './components/documents/fiba-popup-document-reject.component';

@NgModule({
    imports: [
        SharedModule,
        FibaFormsModule,
        FibaCommonSelectModule,
    ],
    declarations: [
        ...COMMON_COMPONENTS,
    ],
    exports: [
        ...COMMON_COMPONENTS,
    ],
})
export class FibaCommonModule {
}
