import { SortDescriptor } from '@progress/kendo-data-query';

function getValue(obj:any, field:string) {
    let atts = field.split('.');
    let value:any = obj;
    for (let att of atts) {
        value = value[att];
    }
    return value;
}

export function getGridSortFn(sortDescriptor:SortDescriptor):(a:any, b:any) => number {
    let sortFn:(a:any, b:any) => number;
    if (sortDescriptor.dir === 'asc') {
        sortFn = (a, b) => {
            let aValue = getValue(a, sortDescriptor.field);
            let bValue = getValue(b, sortDescriptor.field);
            return ((aValue == bValue) ? 0 : ((aValue > bValue) ? 1 : -1));
        };
    } else {
        sortFn = (a, b) => {
            let aValue = getValue(a, sortDescriptor.field);
            let bValue = getValue(b, sortDescriptor.field);
            return ((aValue == bValue) ? 0 : ((bValue > aValue) ? 1 : -1));
        };
    }
    return sortFn;
}
