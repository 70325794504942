import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'fibaDateNoDays' })
export class FibaDateNoDaysPipe implements PipeTransform {
    transform(value: any, format: string = "MMM-YYYY"): string {
        if ([1900, 9999].indexOf(moment(value).year()) > -1 || !value) {
            return '';
        }
        return moment(value).isValid() ? moment(value).format(format) : value;
    }
}