import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AgentRegistrationFormDTO, AgentTestListItemDTO} from '@fiba/models';
import { AppConfig } from '@app/app.config';

import {HttpService, IFileData} from '../utils/http.service';
import {Guid} from '@fiba/utils';

@Injectable()
export class AgentRegistrationDataService {
    protected agentRegistrationEndpoint:string;

    constructor(protected http:HttpService, protected config:AppConfig) {
        this.agentRegistrationEndpoint = config.agentRegistrationApiEndpoint;
    }

    createAgentRegistration(model:AgentRegistrationFormDTO):Observable<AgentRegistrationFormDTO> {
        return this.http.post(`${this.agentRegistrationEndpoint}`, model);
    }

    fetchRegistrationConfiguration(eventId:number):Observable<AgentTestListItemDTO> {
        return this.http.get(`${this.agentRegistrationEndpoint}/config/${eventId}`);
    }

    fetchAgentRegistration(guid:Guid, password:string):Observable<AgentRegistrationFormDTO> {
        const params:{ [key:string]:string | string[] } = {'password': password};
        return this.http.post(`${this.agentRegistrationEndpoint}/${guid}`, params);
    }

    fetchAgentRegistrationDocument(guid:Guid, password:string):Observable<IFileData> {
        const params:{ [key:string]:string | string[] } = {'password': password};
        return this.http.getFileData(`${this.agentRegistrationEndpoint}/${guid}/registration-document`, params,);
    }

    updateAgentRegistration(guid:Guid, model:AgentRegistrationFormDTO):Observable<any> {
        return this.http.put(`${this.agentRegistrationEndpoint}/${guid}`, model);
    }

    validateAgentRegistration(guid:string, password:string):Observable<AgentRegistrationFormDTO> {
        const params:{ [key:string]:string | string[] } = {'password': password};
        return this.http.put(`${this.agentRegistrationEndpoint}/${guid}/validate`, params);
    }

    public getMyFileFromBackend(guid:Guid, formDocumentGuid:string, password:string, pwc:boolean = false):Observable<IFileData> {
        const params:{ [key:string]:string | string[] } = {'password': password};
        return this.http.getFileData(`${this.agentRegistrationEndpoint}/${guid}/documents/${formDocumentGuid}?pwc=${pwc}`, params);
    }
}
