import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import 'reflect-metadata';
import 'zone.js';

import {AppModule} from './app';
import Environment from './environments/environment';
import {AppConfig, Env} from '@app/app.config';

if (Environment.env === Env.PROD) {
    enableProdMode();
}

/*
// This block was enabled in prod mode but no idea how it is used (while I can guess of course)
// Store URL in local storage for redirection after login
if (window.location.pathname.indexOf('auth-handler') === -1) {
    localStorage.setItem('redirectUrl', window.location.pathname);
}
*/

platformBrowserDynamic([
    {
        provide: AppConfig,
        useValue: new Environment(),
    },
]).bootstrapModule(AppModule)
    .catch(err => console.log(err));
