import { Component, Input, } from '@angular/core';

import { FibaDisplayItemTextComponent } from './fiba-display-item-text.component';
import * as moment from 'moment';
import { TechnicalDelegateTestEventDTO } from '@fiba/models';
@Component({
    selector: 'fibaDisplayItemTextTechnicalDelegateTest',
    templateUrl: './fiba-display-item-text.component.html',
})
export class FibaDisplayItemTextTechnicalDelegateTestComponent extends FibaDisplayItemTextComponent{
    @Input() public event: TechnicalDelegateTestEventDTO = null;    
    protected getItem() {
        if (this._itemCategoryCode && this._itemCode) {
            this.getObservable().subscribe(
                (res) => {
                    this.text = res ? res.description : "";                    
                    this.text = this.text.replace("{{TestDate}}", this.transformDate(this.event.date));
                    this.text = this.text.replace("{{EventTitle}}", this.event.title); 
                    this.text = this.text.replace("{{EventFromDate}}", this.transformDate(this.event.fromDate)); 
                    this.text = this.text.replace("{{EventToDate}}", this.transformDate(this.event.toDate));
                    this.text = this.text.replace("{{EventZone}}", this.transformDate(this.event.zoneDescription));
                },
                (error) => {
                });
        }
    }
    transformDate(value: any, format: string = "D-MMM-YYYY"): string {
        if ([1900, 9999].indexOf(moment(value).year()) > -1 || !value) {
            return '';
        }
        return moment(value).isValid() ? moment(value).format(format) : value;
    }
}
