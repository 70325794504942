import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FibaLoadingService } from './fiba-loading.service';

import { FibaLoadingComponent } from './fiba-loading.component';

const EXTERNAL_COMPONENTS: any[] = [
    FibaLoadingComponent,
];

export * from './fiba-loading.service';
export * from './fiba-loading.component';

const INTERNAL_COMPONENTS: any[] = [];

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        INTERNAL_COMPONENTS,
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
    providers: [
        FibaLoadingService,
    ],
})
export class LoadingModule {
}
