import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

export interface FibaLoadingOption {
    show: boolean;
    threshold: number;
    loadingText: string;
}

@Injectable()
export class FibaLoadingService {

    public loadingSubject: BehaviorSubject<FibaLoadingOption> = new BehaviorSubject<FibaLoadingOption>({ show: false, threshold: 800, loadingText: null });

    show(threshold: number, loadingText: string) {
        //TODO: Reactivate loading when fixed in Angular 5
        this.loadingSubject.next({ show: true, threshold: threshold, loadingText: loadingText });
    }

    hide() {
        this.loadingSubject.next({ show: false, threshold: 0, loadingText: null });
    }

    getMessage(): Observable<any> {
        return this.loadingSubject.asObservable();
    }
}