import { Component, forwardRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputEmail',
    templateUrl: './fiba-input-email.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputEmailComponent),
            multi: true,
        },
    ],
    host: {class: 'fiba-input'},
})
export class FibaInputEmailComponent extends FibaInputBase<string> {
    constructor(protected injector:Injector) {
        super(injector);
    }
}
