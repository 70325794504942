import { Component, EventEmitter, forwardRef, Injector, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FibaInputBase } from '../base/fiba-input-base';

const toDayString = (val:Date):string => (
    `${('0' + val.getUTCDate()).slice(-2)}/${('0' + (val.getUTCMonth() + 1)).slice(-2)}/${val.getUTCFullYear()}`
);

@Component({
    selector: 'fibaInputDateTime',
    templateUrl: './fiba-input-date-time.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputDateTimeComponent),
            multi: true
        },
    ],
    host: {class: 'fiba-input'},
})
export class FibaInputDateTimeComponent extends FibaInputBase<Date> {

    public date:Date;
    public time:Date;

    @Input() public canSetHasTime = false;
    @Input() public disabledDate = false;
    @Input() public disabledTime = false;
    @Input() public absoluteTimeMode = false;
    @Input() public hasTime = true;
    @Output() public hasTimeChange:EventEmitter<boolean> = new EventEmitter();
    @Output() public change:EventEmitter<Date> = new EventEmitter();

    constructor(protected injector:Injector) {
        super(injector);
    }

    public get value():Date {
        return this._value as Date;
    }

    public set value(value:Date) {
        let val:Date;

        if (typeof value === 'string') { // Safety conversion
            val = value;
            if (value === '') {
                this._value = undefined;
                return;
            }
            const strVal:string = <string>value;
            if (strVal.charAt(4) === '-') {
                val = new Date(Date.parse(strVal.concat('Z')));
                if (val) {
                    val.setTime(val.getTime() + val.getTimezoneOffset() * 60000);
                }
            } else {
                val = new Date(strVal);
            }
        } else if (value instanceof Date && value !== undefined) {
            val = value;
        }

        if (value && val !== this._value) {
            this._value = val;
            this.date = val;
            this.time = val;
        }
    }

    public handleDateChange(value:Date):void {
        let val:Date;

        if (!this.time) {
            this.time = new Date(new Date().setHours(0, 0));
        }
        if (this.time && value && this.time) {
            val = new Date(value.getFullYear(), value.getMonth(), value.getDate(), this.time.getHours(), this.time.getMinutes());
            if (!this.absoluteTimeMode) {
                val.setTime(val.getTime() - val.getTimezoneOffset() * 60000);
            }
        }
        this._value = val;
        this.applyChangeLogic();
        this.onTouched();
    }

    public handleTimeChange(value:Date):void {
        let val:Date;

        if (this.date && value) {
            val = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), value.getHours(), value.getMinutes());
            if (!this.absoluteTimeMode) {
                val.setTime(val.getTime() - val.getTimezoneOffset() * 60000);
            }
        }

        this._value = val;
        this.applyChangeLogic();
        this.onTouched();
    }

    public addTime():void {
        this.hasTime = true;
        this.hasTimeChange.emit(this.hasTime);
    }

    public removeTime():void {
        this.hasTime = false;
        this.hasTimeChange.emit(this.hasTime);

        // Set the datetime as the date with 00:00 time
        const val:Date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0);

        // Add the offset to the time to prevent the day -1 bug/feature
        if (!this.absoluteTimeMode) {
            val.setTime(val.getTime() - val.getTimezoneOffset() * 60000);
        }

        // Set the component value with the new dateTime
        this._value = val;

        // Propagate the change
        this.applyChangeLogic();

    }

    public handleBlur():void {
        // If the TIME is null (hours:minutes displayed in the picker after del or backspace) and the date is not...
        if (!this._value && this.date) {
            // Set the datetime as the date with 00:00 time
            const val:Date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0);

            // Add the offset to the time to prevent the day -1 bug/feature
            if (!this.absoluteTimeMode) {
                val.setTime(val.getTime() - val.getTimezoneOffset() * 60000);
            }

            // Set the component value with the new dateTime
            this._value = val;

            // Propagate the change
            this.applyChangeLogic();

            // If the time is nullable, set the property accordingly. Otherwise the time will still be displayed as 00:00
            if (this.canSetHasTime) {
                this.hasTime = false;
                this.hasTimeChange.emit(this.hasTime);
            }
        }
    }

    private applyChangeLogic():void {
        this.onChange(this._value);
        this.change.emit(this._value as Date);
    }

}
