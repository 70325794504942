
<h3 *ngIf="label && label.length > 0">{{label}}</h3>
<div>
    <ng-template [ngIf]="grouped">
        <kendo-grid [data]="documentGrouped"
                    class="inner no-scrollbar"
                    [groupable]="false"
                    [group]="groups"
                    (dataStateChange)="onStateChange($event)"
                    (edit)="editHandler($event)"
                    (remove)="removeHandler($event)"
                    (add)="addHandler($event)">
            <ng-template kendoGridToolbarTemplate position="top" *ngIf="!disabled && (!oneDocument || (oneDocument && !documentGrouped?.total))">
                <button *ngIf="editable" type="button" kendoGridAddCommand class="bg-gold"><i class="fa fa-lg fa-plus-circle" aria-hidden="true"></i>Add new</button>
            </ng-template>
            <kendo-grid-column field="documentType" title="Document type" width="200">
                <ng-template kendoGridGroupHeaderTemplate let-value="value" let-document>
                    {{value}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Title" field="title" class="document-text">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.title}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Type" field="type">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.typeItem?.name}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="documentType == DocumentGenericTypes_NATIONALITY" title="ID number" field="type">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.identificationDocumentNumber}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="documentType==DocumentGenericTypes_NATIONALITY" title="Issue" field="type" width="100">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.identificationDocumentIssueDate | fibaDate}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="documentType==DocumentGenericTypes_NATIONALITY" title="Expiration" field="type" width="100">
                <ng-template kendoGridCellTemplate let-document>
                    <span *ngIf="document?.identificationDocumentExpirationDate">{{document?.identificationDocumentExpirationDate | fibaDate}}</span>
                    <span *ngIf="document?.hasNoExpirationIdentificationDocument">No expiration date</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Uploaded" field="creationDate" width="160">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.lastModificationDateTime | fibaDateTime}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="File name" field="fileName">
                <ng-template kendoGridCellTemplate let-document>
                    <a class="clickable" (click)="downloadHandler(document.formDocumentGuid)">{{document?.fileName}}</a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column *ngIf="!disabled" title="Actions" class="document-action" width="250">
                <ng-template kendoGridCellTemplate let-document>
                    <button *ngIf="document.documentType==documentType && editable" type="button" kendoGridEditCommand class="bg-gold">Edit</button>
                    <button *ngIf="document.documentType==documentType && editable" type="button" kendoGridRemoveCommand>Delete</button>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    </ng-template>
    <ng-template [ngIf]="!grouped">
        <kendo-grid [data]="_model"
                    class="inner no-scrollbar"
                    (dataStateChange)="onStateChange($event)"
                    (edit)="editHandler($event)"
                    (remove)="removeHandler($event)"
                    (add)="addHandler($event)">
            <ng-template kendoGridToolbarTemplate position="top" *ngIf="!disabled && (!oneDocument || (oneDocument && (!_model || (_model && _model?.length == 0))))">
                <button type="button" *ngIf="editable" kendoGridAddCommand class="bg-gold">Add new</button>
            </ng-template>
            <kendo-grid-column title="Title" field="title">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.title}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Type" field="type">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.typeItem?.name}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="documentType==DocumentGenericTypes_NATIONALITY" title="ID number" field="type">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.identificationDocumentNumber}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="documentType==DocumentGenericTypes_NATIONALITY" title="Issue" field="type" width="100">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.identificationDocumentIssueDate | fibaDate}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="documentType==DocumentGenericTypes_NATIONALITY" title="Expiration" field="type" width="100">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.identificationDocumentExpirationDate | fibaDate}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Uploaded" field="creationDate" width="100">
                <ng-template kendoGridCellTemplate let-document>
                    <span>{{document?.lastModificationDateTime | fibaDateTime}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="File name" field="fileName">
                <ng-template kendoGridCellTemplate let-document>
                    <a class="clickable" (click)="downloadHandler(document.documentId)">{{document?.fileName}}</a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column *ngIf="!disabled" title="Actions" width="250">
                <ng-template kendoGridCellTemplate let-document>
                    <button *ngIf="document.documentType==documentType && editable" type="button" kendoGridEditCommand class="bg-gold">Edit</button>
                    <button *ngIf="document.documentType==documentType && editable" type="button" kendoGridRemoveCommand>Delete</button>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    </ng-template>
</div>
<fibaPopupDocument [model]="editDataItem" [isNew]="isNew"
                   [documentName]="documentName"
                   [originalDocumentId]="originalDocumentId"
                   [categoryCode]="categoryCode"
                   [parentCode]="parentCode"
                   [documentType]="documentType"
                   [entityTypeBase]="entityTypeBase"
                   [entityIdBase]="entityIdBase"
                   [entityType]="entityType"
                   [entityId]="entityId"
                   [showComment]="showComment"
                   (save)="saveHandler($event)"
                   (cancel)="cancelHandler()"
                   [saveMethod]="saveMethod">
</fibaPopupDocument>
