import { Attribute, Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[fibaGreaterValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => FibaGreaterValidator), multi: true}
    ]
})
export class FibaGreaterValidator implements Validator {
    constructor(@Attribute('fibaGreaterValidator') public fibaGreaterValidator:string, @Attribute('lowestDate') public lowestDate:string) {
    }

    private get isLowest():boolean {
        if (!this.lowestDate) return false;
        return this.lowestDate === 'true' ? true : false;
    }

    validate(me:AbstractControl):{ [key:string]:any } {
        // self value
        let myValue = me.value;

        // control value
        let other = me.root.get(this.fibaGreaterValidator);

        // if any of the 2 fields is empty, it's ok.
        if (!myValue || !other || !other.value) {
            this.deleteOtherError(other);
            return undefined;
        }

        // this value must be greater or equal than the reverse
        if (!this.isLowest) {
            if (myValue < other.value) {
                return {
                    fibaGreaterValidator: true
                }
            } else {
                this.deleteOtherError(other);
                return undefined;
            }
        } else {
            // reverse value must be lower or equal
            if (myValue > other.value) {
                return {fibaGreaterValidator: true};
            } else {
                this.deleteOtherError(other);
                return undefined;
            }
        }
    }

    private deleteOtherError(other:AbstractControl):void {
        if (other && other.errors) {
            delete other.errors['fibaGreaterValidator'];
            if (!Object.keys(other.errors).length) {
                other.setErrors(undefined);
            }
        }
    }
}
