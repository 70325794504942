<label *ngIf="label && label.length > 0" for="{{name}}">{{label}}</label>
<div>
    <kendo-datepicker (valueChange)="handleValue()"
                      [(ngModel)]="dateToDisplay"
                      [disabled]="disabled"
                      [format]="'dd-MMM-yyyy'"
                      id="{{name}}"
    ></kendo-datepicker>

    <!--<br> DateToDisplay: {{ dateToDisplay }} <br>-->
    <!--Value: {{ value }}<br>-->
    <!--Pristine: {{ ngControl.control.pristine }}-->

    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                &nbsp;{{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.fibaGreaterValidator">
                Start date must be lower than end date
            </li>
        </ul>
    </div>
</div>

