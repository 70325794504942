import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'commaSeparated' })
export class CommaSeparatedPipe implements PipeTransform {
    transform(values: Array<string|number>): string {
        if (values) {
            return values.join(', ');
        }
        return '';
    }
}
