import { Injectable } from "@angular/core";
import { AuditInfos, IAuditable } from "@fiba/models";

// TODO review 2018-03-20 : should this be this static instead ?
@Injectable()
export class AuditUtilsService {
    getMostRecentAuditInfos(auditableList:IAuditable[]):IAuditable {
        let result = new AuditInfos();
        if (auditableList) {
            let mostRecentAuditable =
                (auditableList
                    .filter(x => !!x.lastModificationDateTime) // trim null/undefined dates
                    .sort((a, b) => {
                        let dateA = new Date(a.lastModificationDateTime);
                        let dateB = new Date(b.lastModificationDateTime);
                        return dateB.getTime() - dateA.getTime();
                    })) // sort by descending order
                    [0]; // get first (or undefined if none)

            if (mostRecentAuditable) {
                result.lastModificationDateTime = mostRecentAuditable.lastModificationDateTime;
                result.lastModificationUserName = mostRecentAuditable.lastModificationUserName;
            }
        }

        return auditableList && result; // if auditableList is null/undefined give back same, else return result
    }
}
