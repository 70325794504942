import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CountryDTO } from '@fiba/models';
import { AppConfig } from '@app/app.config';

import { DataCachedService } from '../utils/data-cached.service';


@Injectable()
export class CountryDataService {
    protected apiEndpoint: string;

    constructor(protected dataService: DataCachedService<CountryDTO>, protected config: AppConfig) {
        this.apiEndpoint = config.countryApiEndpoint;
    }

    fetchCountries(includeInactive: boolean = false): Observable<CountryDTO[]> {
        return this.dataService.fetchEntities(`${this.apiEndpoint}?includeInactive=${includeInactive}`, `countries?includeInactive=${includeInactive}`);
    }
}
