import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { FibaAjaxSelectBaseComponent } from '@fiba/forms';
import { ItemDTO } from '@fiba/models';
import { ItemDataService } from '@fiba/data-services';

@Component({
    selector: 'fibaSelectItem',
    templateUrl: '../../forms/base/fiba-select-base.component.html', providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaSelectItemComponent),
            multi: true
        }
    ],
    host: {'class': 'fiba-input'}
})
export class FibaSelectItemComponent extends FibaAjaxSelectBaseComponent<ItemDTO> implements OnInit {
    @Input() protected categoryCode;
    @Input() protected displayParent:boolean;
    @Input() protected showOnlyRoots:boolean = false;
    @Input() protected showOnlyChildren:boolean = false;

    constructor(protected injector:Injector, protected dataService:ItemDataService) {
        super(injector);
        this._valueField = 'itemId';
    }

    @Input()
    protected set textField(value:string) {
        this._textField = value;
    }

    @Input()
    protected set valueField(value:string) {
        this._valueField = value || 'itemId';
    }

    protected _parentCode:string;

    @Input()
    protected set parentCode(value:string) {
        this._parentCode = value;
        this.getSubscription();
    };

    protected _parentId:number;

    @Input()
    protected set parentId(value:number) {
        this._parentId = value;
        this.getSubscription();
    };

    ngOnInit():void {
        super.ngOnInit();
        this._textField = this._textField || (this.displayParent ? 'cli_displayNameWithParent' : 'name');
    }

    getBindData():(data:ItemDTO[]) => void {
        return (data:ItemDTO[]) => {
            this.getBindDataCallbackBase(data);
        };
    }

    getObservable():Observable<ItemDTO[]> {
        return this.dataService.fetchItems(this.categoryCode);
    }

    protected getBindDataCallbackBase(data:ItemDTO[]):void {
        if (this._parentCode) {
            let parentId = data.find(i => i.code == this._parentCode).itemId;
            data = data.filter(i => i.parentItemId == parentId);
        }
        if (this._parentId) {
            data = data.filter(i => i.parentItemId == this._parentId);
        }
        if (this.showOnlyRoots) {
            data = data.filter(i => !i.parentItemId);
        }
        if (this.showOnlyChildren) {
            data = data.filter(i => i.parentItemId != null);
        }
        data = this.sortItemsList(data);
        this._originalItems = data.map(a => {
            a.cli_displayNameWithParent = (a.parentItem ? `${a.parentItem.name} / ` : "") + `${a.name}`;
            return a;
        });
        this._filteredItems = data.slice(0);
    }

    protected sortItemsList(data:ItemDTO[]):ItemDTO[] {
        if (data) {
            data = data.sort((x, y) => {
                if (x.displayOrder === y.displayOrder) {
                    return !x.parentItemId ? -1 : 1;
                }
                return x.displayOrder < y.displayOrder ? -1 : 1;
            });
        }
        return data;
    }
}
