import { NgModule } from '@angular/core';

import { TopMenuComponent } from './topmenu.component';
import { SharedModule } from '@app/shared.module';

const EXTERNAL_COMPONENTS:any[] = [
    TopMenuComponent,
];

const INTERNAL_COMPONENTS:any[] = [];

@NgModule({
    imports: [
        SharedModule.forRoot(),
    ],
    declarations: [
        INTERNAL_COMPONENTS,
        EXTERNAL_COMPONENTS,
    ],
    exports: [
        EXTERNAL_COMPONENTS,
    ],
    providers: [],
})
export class TopMenuModule {
}
