import { Directive, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FibaMultiInputBaseComponent } from './fiba-multi-input-base.component';

/**
 * Abstract class for MultiSelect game-information
 */
@Directive()
export abstract class FibaMultiSelectBaseComponent extends FibaMultiInputBaseComponent implements OnInit {

    /*
    // Is it still used ?
    public static getBaseTemplate(): string {
        return require('./fiba-multiselect-base.component.html');
    }

    // Is it still used ?
    public static getBaseTreeviewTemplate(): string {
        return require('./fiba-multiselect-treeview.component.html');
    }
    */

    public _textField:string;
    public _valueField:string;
    public _data:any | any[];
    @Input() public type = 'filter'; // filter or input
    @Input() protected items:any[];
    @Input() protected valuePrimitive = true;
    @Input() protected disabled = false;

    private _subscription:Subscription;

    /**
     * Initialices the component
     */
    public ngOnInit():void {
        this._subscription = this.getSubscription();
    }

    /**
     * Destroyes the component
     */
    public ngOnDestroy():void {
        this._subscription.unsubscribe();
    }

    /**
     * Handles the filter event
     * @param value
     */
    public handleFilter(value) {
        this.onTouched();
        this._data = this.items.filter((s) =>
            s[this._textField].toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    /**
     * Handles the value event
     * @param value
     */
    public handleValue(value) {
        this.onTouched();
    }

    protected abstract getSubscription():Subscription;

}
