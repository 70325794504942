import { Component, forwardRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base'

@Component({
    selector: 'fibaCheckbox',
    templateUrl: './fiba-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaCheckboxComponent),
            multi: true
        }
    ],
    host: {'class': 'fiba-input'}
})
export class FibaCheckboxComponent extends FibaInputBase<boolean> {
    constructor(protected injector:Injector) {
        super(injector);
    }
}
