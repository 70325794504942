import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CityDataService } from '@fiba/data-services';
import { FibaInputBase } from '@fiba/forms';
import { City } from '@fiba/models';

import * as moment from 'moment-timezone';
import { from, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

/**
 Component's ngModel must be bound to a string
 City value is managed as internal value
 (This is why it doesn't extend FibaInputAutocompleteBase)
 */
@Component({
    selector: 'fibaAutocompleteCity',
    templateUrl: './fiba-autocomplete-city.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaAutocompleteCityComponent),
            multi: true,
        },
    ],
    host: {class: 'fiba-input'},
    styleUrls: ['./fiba-autocomplete-city.component.css'],
})
export class FibaAutocompleteCityComponent extends FibaInputBase<City> implements OnInit {
    public textField:string;
    public valueField:string;
    public data:Observable<City[]>;
    public valueCity:City;
    public searchTerms = new Subject<string>();
    public currentDate = Date.now();
    public timezones:any[] = [];
    public isFrontOffice:boolean = null;
    @Input() public useIanatimeZone:boolean = false;
    @Input() public placeholder:string;
    @Input() public type:string = 'filter';
    @Input() public delay:number = 300;
    @Input() public disabled:boolean = false;

    @Output() public change:EventEmitter<any> = new EventEmitter();

    @Input() public country:string = null;

    @Output() public cityChange:EventEmitter<string> = new EventEmitter();
    @Output() public ianatimeZone:EventEmitter<string> = new EventEmitter();

    constructor(
        protected injector:Injector,
        private service:CityDataService) {
        super(injector);
        this.data = this.searchTerms.asObservable().pipe(
            debounceTime(this.delay),
            distinctUntilChanged(),
            switchMap((term) => this.getObservable(term))
        )

        this.data.subscribe((item) => {
        });
    }

    public ngOnInit():void {
        super.ngOnInit();
        this.valueField = 'geonameId';
        this.textField = 'name';
        this.timezones = moment.tz.names();
    }

    public getObservable(city:string):Observable<City[]> {
        if (city) {
            return this.service.fetchCities(city, this.country);
        } else {
            return from([]);
        }
    }

    public writeValue(value:City):void {
        this.value = value;
        if (value) {
            this.valueCity = {
                name: value.name,
                displayName: value.displayName,
                geonameId: value.geonameId,
                timeZoneId: value.timeZoneId,
            } as City;
        } else {
            this.valueCity = null;
        }
    }

    public handleFilter(value:string):void {
        if (value) {
            this.searchTerms.next(value);
        } else {
            this.handleValue(null);
        }
        this.onTouched();
    }

    public handleValue(value:City):void {
        if (value) {
            this.cityChange.emit(value.name);
            this.ianatimeZone.emit(value.timeZoneId);
            this.writeValue(value);
        } else {
            this.cityChange.emit(null);
            this.ianatimeZone.emit(null);
            this.writeValue(null);
        }
        this.manualChange();
        this.onTouched();
    }

    public valueNormalizer = (text:Observable<string>) => text.pipe(map((text:string) => {
        return {
            name: text,
            displayName: text,
        } as City;
    }));
}
