import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FibaLoadingOption } from '@fiba/loading';
import { FibaLoadingService } from '@fiba/loading/fiba-loading.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'fibaLoading',
    templateUrl: './fiba-loading.component.html',
    styleUrls: ['./fiba-loading.component.css'],
    inputs: [],
    encapsulation: ViewEncapsulation.None,
})

export class FibaLoadingComponent implements OnDestroy {

    public loadingText: string = '';

    public zIndex: number = 9999;

    public subscription: Subscription;
    public showSpinner: string = 'hidden';

    private timer: any;

    constructor(private loadingService: FibaLoadingService) {
        this.createServiceSubscription();
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public createServiceSubscription() {
        

        this.subscription =
            this.loadingService.getMessage()
            .subscribe((option: FibaLoadingOption) => {
                    if (option.show) {
                        this.loadingText = option.loadingText;
                        if (!!this.timer) {
                            return;
                        }

                        this.timer = setTimeout(function () {
                            this.timer = null;
                            this.showSpinner = 'visible';
                        }.bind(this), option.threshold);
                        
                    } else {
                        clearTimeout(this.timer);
                        this.timer = null;
                        this.showSpinner = 'hidden';
                    }
                });
    }
}
