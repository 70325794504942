<label *ngIf="label && label.length > 0" for="{{name}}">{{label}}</label>
<kendo-switch (valueChange)="manualChange()"
              [(ngModel)]="value"
              [disabled]="disabled"
              [offLabel]="offLabel"
              [onLabel]="onLabel"
              [required]="required"
              id="{{name}}"></kendo-switch>
<div *ngIf="ngControl.errors" class="validation-errors">
    <ul>
        <li [hidden]="!ngControl.errors.required">
            {{label}} is required
        </li>
    </ul>
</div>
