import { Directive, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive()
export abstract class FibaPopupBase<T> {
    @Input() public contextCode:string;

    public active:boolean = false;
    @Input() public isNew:boolean = false;
    @Output() public cancel:EventEmitter<any> = new EventEmitter();
    @Output() public save:EventEmitter<T> = new EventEmitter();
    @ViewChild('form') public ngForm:NgForm;

    constructor(protected injector:Injector) {
    }

    protected _model:T;

    @Input()
    public set model(pn:T) {
        this.active = pn != undefined && pn !== null;
        this._model = pn;
        if (this.onModelSet) {
            this.onModelSet(this._model);
        }
    }

    public onModelSet(model:T):void {
    }

    protected onSave(e):void {
        e.preventDefault();
        if (!this.ngForm || this.ngForm.valid) {
            this.save.emit(this._model);
            this.active = false;
        } else {
            // Mark all inputs as touched
            for (const key in this.ngForm.form.controls) {
                this.ngForm.form.controls[key].markAsTouched();
            }
        }
    }

    protected onCancel(e):void {
        e.preventDefault();
        this.closeForm();
    }

    protected closeForm():void {
        this.active = false;
        this.cancel.emit();
    }
}
