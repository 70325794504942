import { HttpService } from './utils/http.service';
import { DataCachedService } from './utils/data-cached.service';
import { AgentTestDataService } from './agent/agent-test-data.service'
import { AgentPreRegistrationDataService } from './agent/agent-pre-registration-data.service'
import { AgentRegistrationDataService } from './agent/agent-registration-data.service'
import { ItemDataService } from './common/item-data.service';
import { CityDataService } from './common/city-data.service';
import { CountryDataService } from './common/country-data.service';
import { DocumentDataService } from './common/document-data.service';
import { DocumentStatusDataService } from './common/document-status-data.service';
import { AgentRegistrationDocumentStatusDataService } from './agent/agent-registration-document-status-data.service';
import { PaymentDataService } from './payment/payment-data.service';
import { OrganisationDataService } from './organisation/organisation-data.service';
import { TechnicalDelegateConfirmationDataService } from './technical-delegate/technical-delegate-confirmation-data.service';
import { TableOfficialEmailConfirmationDataService } from './table-official/table-official-email-confirmation-data.service';
import { PlayerReleaseConfirmationDataService } from './player-release/player-release-confirmation-data.service';
import { PlayerReleaseDocumentStatusDataService } from './player-release/player-release-document-status-data.service';
import { GameOfficialConfirmationDataService } from './GameOfficial/game-official-confirmation-data.service';
import { EquipmentApplicationDataService } from './equipment/equipment-application-data.service';


export const DATA_SERVICES_PROVIDERS: any[] = [
    HttpService,
    DataCachedService,
    AgentTestDataService,
    AgentPreRegistrationDataService,
    AgentRegistrationDataService,
    ItemDataService,
    CityDataService,
    CountryDataService,
    DocumentDataService,
    DocumentStatusDataService,
    AgentRegistrationDocumentStatusDataService,
    PaymentDataService,
    OrganisationDataService,
    TechnicalDelegateConfirmationDataService,
    TableOfficialEmailConfirmationDataService,
    PlayerReleaseConfirmationDataService,
    PlayerReleaseDocumentStatusDataService,
    GameOfficialConfirmationDataService,
    EquipmentApplicationDataService
];

export { IFileData } from './utils/http.service';
export { AgentTestDataService } from './agent/agent-test-data.service'
export { AgentPreRegistrationDataService } from './agent/agent-pre-registration-data.service'
export { AgentRegistrationDataService } from './agent/agent-registration-data.service'
export { ItemDataService } from './common/item-data.service';
export { CityDataService } from './common/city-data.service';
export { CountryDataService } from './common/country-data.service';
export { DocumentDataService } from './common/document-data.service';
export { DocumentStatusDataService } from './common/document-status-data.service';
export { AgentRegistrationDocumentStatusDataService } from './agent/agent-registration-document-status-data.service';
export { PaymentDataService } from './payment/payment-data.service';
export { OrganisationDataService } from './organisation/organisation-data.service';
export { TechnicalDelegateConfirmationDataService } from './technical-delegate/technical-delegate-confirmation-data.service';
export { TableOfficialEmailConfirmationDataService } from './table-official/table-official-email-confirmation-data.service';
export { PlayerReleaseConfirmationDataService } from './player-release/player-release-confirmation-data.service';
export { PlayerReleaseDocumentStatusDataService } from './player-release/player-release-document-status-data.service';
export { GameOfficialConfirmationDataService } from './GameOfficial/game-official-confirmation-data.service';
export { EquipmentApplicationDataService } from './equipment/equipment-application-data.service';
