<nav class="tabs">
    <ul>
        <ng-template ngFor let-tab [ngForOf]="_tabs">
            <li *ngIf="tab.children" class="parent" [ngClass]="{'selected': tab.selected, 'active': tab.active}">
                <span (click)="openParent(tab.name)">
                    <i *ngIf="tab.icon!=''" class="fa fa-lg {{tab.icon}}"></i><strong>{{tab.name | uppercase}}</strong>
                </span>
                <ul *ngIf="tab.children">
                    <li *ngFor="let child of tab.children" [routerLink]="child.route" [routerLinkActive]="['active']"
                        (click)="switchActiveParent(tab.name)">
                        <i *ngIf="child.icon!=''" class="fa fa-lg {{child.icon}}"></i>{{child.name}}
                    </li>
                </ul>
            </li>
            <li *ngIf="!tab.children" [routerLink]="tab.route" [routerLinkActive]="['active']" class="single">
                <i *ngIf="tab.icon!=''" class="fa fa-lg {{tab.icon}}"></i>{{tab.name}}
            </li>
        </ng-template>
    </ul>
</nav>
