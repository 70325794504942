<label *ngIf="label && label.length > 0" for="{{name}}">{{label}}</label>
<div class="fill-all">
    <textarea #input="ngModel"
              (blur)="onTouched($event)"
              (change)="manualChange()"
              [(ngModel)]="value"
              [disabled]="disabled"
              id="{{name}}"
              name="{{name}}"
              placeholder="{{placeholder}}"
    ></textarea>
    <div *ngIf="ngControl?.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>
