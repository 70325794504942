import * as moment from 'moment';

/**
 * Filterable Class used for single filters in dropdown inputs
 */
export class Filterable {
    public value: any; // value picked in the dropdown
    protected filterName: string;
    protected _idField: string;
    public get idField() {
        return this._idField;
    }

    /**
     If the field containing the id has the same name than the filter name, you can omit the idField parameter
     @param filterName Name of the filter in the API
     @param idField Name of the field (in the JS object) containing the ID you want to filter
     */
    constructor(filterName: string, idField: string = null) {
        this.filterName = filterName;
        this._idField = idField === null ? filterName : idField;
    }

    isSet(): boolean {
        return typeof this.value !== 'undefined' && this.value !== null;
    }

    equals(id: any): boolean {
        return this.value[this._idField] === id;
    }

    getFilter(): string {
        return this.value[this._idField].toString();
    }

    reset(): void {
        this.value = null;
    }

    setValue(value: any): void {
        this.value = value;
    }

    setValuePrimitive(value: any): void {
        this.value[this._idField] = value;
    }

    getValue(): any {
        if (this.isSet()) {
            return this.value[this._idField];
        }
    }
}

/**
 * FilterableArray Class used for filters in multiselect inputs
 */
export class FilterableArray {
    protected value: any[]; // value picked in the dropdown
    protected filterName: string;
    protected idField: string;

    /**
     If the field containing the id has the same name than the filter name, you can omit the idField parameter
     @param filterName Name of the filter in the API
     @param idField Name of the field (in the JS object) containing the ID you want to filter
     */
    constructor(filterName: string, idField: string = null) {
        this.filterName = filterName;
        this.idField = idField === null ? filterName : idField;
        this.value = [];
    }

    /**
     *  Gets the filters
     * @returns an array with the values of the filter converted into string
     */
    getFilter(): string[] {
        if (this.value) {
            return this.value.map(v =>
                v[this.idField].toString()
            );
        } else {
            return [];
        }
    }

    /**
     * Gets the filter Name
     * @returns the filter name
     */
    getFilterName(): string {
        return this.filterName;
    }

    /**
     * Gets whether there is any filter with the specified id
     * @param id of the filter
     * @returns true if the filter id is present in the set of selected filters.
     */
    contains(id: any): boolean {
        return this.value && this.value.filter(x => x[this.idField] === id).length > 0;
    }

    /**
     * Gets whether there is any filter assigned
     * @returns true if there is any filter, false otherwise
     */
    any(): boolean {
        return this.value && this.value.length > 0;
    }

    /**
     * Resets the filter values
     */
    reset(): void {
        this.value = [];
    }

    /**
     * set the filter value
     */
    setFilterValue(value: any[]): void {
        this.value = value;
    }

    addFilterValue(value: any): void {
        this.value.push(value);
    }
}

const DEFAULT_TAKE = 30;

export class BaseFilters {
    public take: number;
    public skip: number;
    public sortBy: string;
    public sortOrder: string;

    constructor(take = DEFAULT_TAKE, skip = 0) {
        this.take = take;
        this.skip = skip;
    }

    getSearchParams(): { [key: string]: string | string[] } {
        let params: { [key: string]: string | string[] } = {};

        for (let filterKey in this) {
            if (this.hasOwnProperty(filterKey)) {
                let filter: any = this[filterKey];
                switch (typeof filter) {
                    case 'number':
                    case 'string':
                    case 'boolean':
                        params[filterKey] = String(filter);
                        break;
                    case 'object':
                        if (filter instanceof FilterableArray) {
                            params[filter.getFilterName()] = filter.getFilter();
                        } else if (filter instanceof Date) {
                            params[filterKey] = moment.utc(filter).format('D-MMM-YYYY');
                        } else if (filter && filter.value) {
                            params[filter.filterName] = String(filter.value[filter.idField]);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return params;
    }

    reset(): void {

    }

    clone(): any {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }
}
