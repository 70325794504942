import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface TabListItem {
    name: string;
    route?: string;
    order: number;
    icon?: string;
    selected?: boolean;
    active?: boolean;
    children?: TabListItem[];
}

@Component({
    selector: 'fibaTabList',
    templateUrl: './tablist.component.html',
    styleUrls: ['./tablist.component.css'],
})
export class TabListComponent {
    public _tabs: TabListItem[];
    protected tabsTmp: TabListItem[] = [];
    protected route: string; // last segment of current route

    @Input()
    protected set tabs(tabs: TabListItem[]) {
        if (this.activatedRoute.firstChild) {
            this.route = this.activatedRoute.firstChild.routeConfig.path;
        } else {
            this.route = null;
        }
        this.processTabs(tabs);
    }

    constructor(protected activatedRoute: ActivatedRoute, protected router: Router) { }

    private static insertTabListItem(item: TabListItem, tabs: TabListItem[]): void {
        let i;
        for (i = 0; i < tabs.length; i++) {
            if (tabs[i].order > item.order) {
                break;
            }
        }
        tabs.splice(i, 0, item);
    }

    // Check if tab is authorized, and if so, add it to targetTabs
    private getAuthorizedTab(tab: TabListItem): Promise<TabListItem> {
        if (tab.route) { // Tab has a route => no children
            return Promise.resolve(tab);
        } else if (tab.children) { // Tab has children
            const promises = [] as Array<Promise<TabListItem>>;
            for (const subTab of tab.children) {
                promises.push(this.getAuthorizedTab(subTab));
            }
            return Promise.all(promises).then((subTabs) => {
                const tabsTmp = [] as TabListItem[];
                let selected = false;
                for (const subTab of subTabs) {
                    if (subTab != null) {
                        TabListComponent.insertTabListItem(subTab, tabsTmp);
                        if (subTab.route == this.route) {
                            selected = true;
                        }
                    }
                }
                if (tabsTmp.length > 0) {
                    return {
                        name: tab.name,
                        order: tab.order,
                        children: tabsTmp,
                        selected,
                        active: selected,
                    } as TabListItem;
                } else {
                    return null;
                }
            });
        }
    }

    protected processTabs(tabs: TabListItem[]): void {
        this._tabs = [];
        for (const tab of tabs) {
            this.getAuthorizedTab(tab).then((tab) => {
                if (tab != null) {
                    TabListComponent.insertTabListItem(tab, this._tabs);
                }
            });
        }
    }

    protected openParent(tabName: string): void {
        for (const tab of this._tabs) {
            tab.selected = (tab.name === tabName);
        }
    }

    protected switchActiveParent(tabName: string): void {
        for (const tab of this._tabs) {
            tab.active = (tab.name === tabName);
        }
    }
}
