import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { Notification, NotificationService } from '@fiba/utils/notification.service';

@Component({
    selector: 'fibaNotificationDisplay',
    templateUrl: './notification-display.component.html',
    styleUrls: ['./notification-display.component.css'],
})
export class NotificationDisplayComponent implements OnInit {
    public notifications:Notification[];

    private subscription:Subscription;
    private allNotifications:Notification[];

    constructor(private notificationService:NotificationService, private sanitizer:DomSanitizer) {
        this.notifications = [];
        this.allNotifications = [];
    }

    public ngOnInit():void {
        this.subscription = this.notificationService.getObservable().subscribe(
            (notif) => {
                this.showNotif(notif);
                this.allNotifications.unshift(notif);
                if (this.allNotifications.length > 10) {
                    this.allNotifications.pop();
                }
            },
        );
    }

    public sanitize(url:string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    protected ngOnDestroy():void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    protected clear():void {
        this.notifications = [];
    }

    protected remove(index:number):void {
        this.notifications.splice(index, 1);
    }

    protected showNotif(notif:Notification):void {
        const element = document.getElementsByTagName('fibaNotificationDisplay')[0];
        element.scrollIntoView(false);
        this.notifications.unshift(notif);
        setTimeout(() => {
            this.eraseNotifByGuid(notif.guid);
        }, notif.ttl * 1000);
    }

    protected eraseNotifByGuid(guid:string):void {
        const index = this.notifications.findIndex((notif) => notif.guid == guid);

        if (index > -1) {
            this.notifications.splice(index, 1);
        }
    }
}
