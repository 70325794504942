import { Pipe, PipeTransform } from '@angular/core';
import * as Moment from 'moment-timezone';

@Pipe({ name: 'fibaUtcOffset' })
export class FibaUtcOffsetPipe implements PipeTransform {
    transform(value, format: string): string {
        if (format) {
            let momentDate = Moment.tz(value, format);
            return 'UTC' + momentDate.format('Z');
        }
        return '';
    }
}
