import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class NotifierServiceBase<T> {
    protected observable:Observable<T>;
    protected notifier:EventEmitter<T>;

    constructor() {
        this.notifier = new EventEmitter<T>();
        this.observable = Observable.create(observer => {
            this.notifier.subscribe(
                notification => {
                    observer.next(notification);
                }
            );
        });
    }

    getObservable():Observable<T> {
        return this.observable;
    }

    protected emit(notif:T):void {
        this.notifier.emit(notif);
    }
}
