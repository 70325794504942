import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[fibaEmailValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => FibaEmailValidator), multi: true}
    ]
})
export class FibaEmailValidator implements Validator {

    protected pattern = /^(([^<>()\[\]\.,;:\s@\"\u00C0-\u017F]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    validate(c:AbstractControl):{ [key:string]:any } {
        // self value
        let v = c.value;

        if (!this.pattern.test(v) && v != null && v != '') {
            c.setErrors({fibaEmailValidator: true});
            return {
                fibaEmailFibaValidator: true
            }
        } else {
            if (c.errors) {
                delete c.errors['fibaEmailValidator'];
                if (!Object.keys(c.errors).length) {
                    c.setErrors(null);
                }
            }
        }

        return null;
    }
}
