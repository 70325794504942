import { } from '../common/base.filters';

declare module '../dtos/generated/agent-test-list-item-dto' {
    export interface AgentTestListItemDTO {
        cli_isSessionClosed: boolean;
    }
}

export class AgentPreRegistrationQuiz {
    nbpa: boolean = null;
    homeCountry: boolean = null;
}

declare module '../dtos/generated/agent-registration-form-dto' {
    export interface AgentRegistrationFormDTO {
        cli_confirmPassword: string;
    }
}