import { Component, Input, } from '@angular/core';

import { FibaDisplayItemTextComponent } from './fiba-display-item-text.component';
import * as moment from 'moment';
import { TableOfficialPublicOLRInformationDTO } from '../../models';
@Component({
    selector: 'fibaDisplayItemTextTableOfficialEmailValidation',
    templateUrl: './fiba-display-item-text.component.html',
})
export class FibaDisplayItemTextTableOfficialEmailValidationComponent extends FibaDisplayItemTextComponent{
    @Input() public olrInformation: TableOfficialPublicOLRInformationDTO = null;    
    protected getItem() {
        if (this._itemCategoryCode && this._itemCode) {
            this.getObservable().subscribe(
                (res) => {
                    this.text = res ? res.description : "";
                    this.text = this.text.replace("{{NationalFederationDisplayText}}", this.olrInformation.nationalFederationDisplayName);
                    this.text = this.text.replace("{{PeriodStartDate}}", this.transformDate(this.olrInformation.periodStartDate));
                    this.text = this.text.replace("{{PeriodEndDate}}", this.transformDate(this.olrInformation.periodEndDate));
                },
                (error) => {
                });
        }
    }
    transformDate(value: any, format: string = "D-MMM-YYYY"): string {
        if ([1900, 9999].indexOf(moment(value).year()) > -1 || !value) {
            return '';
        }
        return moment(value).isValid() ? moment(value).format(format) : value;
    }
}
