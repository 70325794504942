import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { City, CityResult } from '@fiba/models';
import { HttpService } from '../utils/http.service';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CityDataService {
    constructor(protected http: HttpClient) {
    }

    fetchCities(cityName: string, countryCode: string): Observable<City[]> {

        const extraParams = !!countryCode ? {
            country: countryCode
        } : {};

        const params: Record<string, string> = {
            username: 'fiba',
            token: 'QzjaK4Zz3Yse37kf55269E7WM7WkE8H5cAeHb2fC',
            maxRows: '10',
            lang: 'en',
            type: 'json',
            orderby: 'relevance',
            featureClass: 'P',
            style: 'full',
            name_startsWith: String(cityName),
            ...extraParams
        };

        const paramsAsString = new HttpParams({fromObject: params}).toString();
        const url = 'https://fiba.geonames.net/searchJSON';
        return this.http.jsonp(`${url}?${paramsAsString}`, 'callback')
            .pipe(map((response) => new CityResult(response).geonames),
                catchError((error) => HttpService.extractErrorMsgCb(error)), // TODO: use a more appropriate error handler
            );
    }
}
