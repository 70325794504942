import {AppConfig, Env} from '@app/app.config';

export default class IntegrationConfig extends AppConfig {
    public static env = Env.PROD;
    private readonly BASE_URL = 'https://map-public.integration.fiba.com';
    // URLs and API endpoints
    public baseUrl:string = this.BASE_URL;
    private readonly API_BASE_URL = 'https://map-api.integration.fiba.com/public';
    public agentTestApiEndpoint = this.API_BASE_URL + '/agent-test';
    public agentPreRegistrationApiEndpoint = this.API_BASE_URL + '/agent-pre-registration';
    public agentRegistrationApiEndpoint = this.API_BASE_URL + '/agent-registration';
    public itemApiEndpoint = this.API_BASE_URL + '/items';
    public countryApiEndpoint = this.API_BASE_URL + '/countries';
    public documentApiEndpoint = this.API_BASE_URL + '/documents';
    public paymentApiEndpoint = this.API_BASE_URL + '/payments';
    public organisationApiEndpoint = this.API_BASE_URL + '/organisations';
    public organisationAutocompleteApiEndpoint = this.API_BASE_URL + '/organisations/autocomplete';
    public technicalDelegateApiEndpoint = this.API_BASE_URL + '/technical-delegate';
    public tableOfficialApiEndpoint = this.API_BASE_URL + '/table-official';
    public playerReleaseApiEndpoint = this.API_BASE_URL + '/player-release';
    public gameOfficialApiEndpoint = this.API_BASE_URL + '/game-official';
    public equipmentApiEndpoint = this.API_BASE_URL + '/equipment';
    public equipmentPartnerApplicationApiEndpoint = this.API_BASE_URL + '/equipment-partner-application';
}
