import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { KendoModule } from './kendo.module';

import { DialogService } from '@progress/kendo-angular-dialog';

import { FibaLoadingService, LoadingModule } from '../@fiba/loading';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        KendoModule.forRoot(),
        LoadingModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        KendoModule,
        LoadingModule,
    ],
    entryComponents: [],
})

export class SharedModule {
    public static forRoot():ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                DialogService, // TODO: Check if necessary
                FibaLoadingService,
            ],
        };
    }
}
