<label for="{{name}}">{{label | nullReplace : ''}}</label>
<input #input="ngModel"
       (change)="manualChange()"
       [(ngModel)]="value"
       [disabled]="disabled"
       class="k-checkbox"
       id="{{name}}"
       type="checkbox"/>
<label class="k-checkbox-label" for="{{name}}"></label>
<div *ngIf="ngControl.errors" class="validation-errors">
    <ul>
        <li [hidden]="!ngControl.errors.required">
            {{label}} is required
        </li>
    </ul>
</div>
