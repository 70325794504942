import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@app/app.config';

import { HttpService } from '../utils/http.service';
import { PlayerReleasePublicConfirmationDTO } from '../../models/dtos/generated/player-release-public-confirmation-dto';

@Injectable()
export class PlayerReleaseConfirmationDataService {
    protected playerReleaseApiEndpoint:string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.playerReleaseApiEndpoint = config.playerReleaseApiEndpoint;
    }

    public getFetchObservable(guid: string): Observable<PlayerReleasePublicConfirmationDTO> {
        return this.http.get(this.playerReleaseApiEndpoint + "/player-release-confirmation/" + guid);
    }

    public getPutObservable(model: PlayerReleasePublicConfirmationDTO):Observable<boolean> {
        return this.http.put(this.playerReleaseApiEndpoint + "/player-release-confirmation", model);
    }

    public sendCode(model: PlayerReleasePublicConfirmationDTO): Observable<PlayerReleasePublicConfirmationDTO>  {
        return this.http.post(this.playerReleaseApiEndpoint + "/send-code", model);
    }

    public validCode(model: PlayerReleasePublicConfirmationDTO):Observable<boolean> {
        return this.http.post(this.playerReleaseApiEndpoint + "/valid-code", model);
    }

}
