import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RootComponent } from './components/root.component';

const routes: Routes = [
    {
        path: '',
        component: RootComponent,
        data: { breadcrumb: 'Home' },
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            {
                path: 'home',
                data: { breadcrumb: 'Home' },
                loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
            },
            {
                path: 'agent-tests',
                loadChildren: () => import('../@fiba/agent-test/agent-test.module').then(m => m.AgentTestModule),
                data: { breadcrumb: 'Agent Test' },
            },
            {
                path: 'agent',
                loadChildren: () => import('../@fiba/agent-pre-registration/agent-pre-registration.module').then(m => m.AgentPreRegistrationModule),
                data: { breadcrumb: 'Agent Pre-registration' },
            },
            {
                path: 'agent',
                loadChildren: () => import('../@fiba/agent-registration/agent-registration.module').then(m => m.AgentRegistrationModule),
                data: { breadcrumb: 'Agent Registration' },
            },
            {
                path: 'payments',
                loadChildren: () => import('../@fiba/payment/payment.module').then(m => m.PaymentModule),
                data: { breadcrumb: 'Payment' },
            },
            {
                path: 'TD_License_Confirmation',
                loadChildren: () => import('../@fiba/technical-delegate/technical-delegate-confirmation.module').then(m => m.TechnicalDelegateConfirmationModule),
                data: { breadcrumb: 'Technical Delegate' },
            },
            {
                path: 'email-address-confirmation',
                loadChildren: () => import('../@fiba/olr/olr-candidate-email-confirmation.module').then(m => m.OlrCandidateEmailConfirmationModule),
                data: { breadcrumb: 'Table Official' },
            },
            {
                path: 'player-release-confirmation',
                loadChildren: () => import('../@fiba/player-release/player-release-confirmation.module').then(m => m.PlayerReleaseConfirmationModule),
                data: { breadcrumb: 'Player Release' },
            },
            {
                path: 'email-confirmation',
                loadChildren: () => import('../@fiba/email-validation/email-validation-confirmation.module').then(m => m.EmailValidationConfirmationModule),
                data: { breadcrumb: 'Game Official' },
            },
            {
                path: 'equipment-partner-application',
                loadChildren: () => import('../@fiba/equipment/equipment-partner-application.module').then(m => m.EquipmentPartnerApplicationModule),
                data: { breadcrumb: 'Equipment Partner Application' },
            },
            {
                path: 'equipment-partner-confirmation',
                loadChildren: () => import('../@fiba/equipment/equipment-partner-confirmation/equipment-partner-confirmation.module').then(m => m.EquipmentPartnerConfirmationModule),
                data: { breadcrumb: 'Equipment Partner Confirmation' },
            },
        ],
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false }),
    ],
    exports: [
        RouterModule,
    ],
    // providers: [AuthGuard, AuthGuardWithToken, AuthGuardNonSpecialCases],
})
export class AppRoutingModule {
}
