export class SortUtils {
    public static sortByThenBy<T>(a:T, b:T, mainSortValueResolver:(x:T) => number, secondarySortValueResolver:(x:T) => number) {
        let mainValueA = mainSortValueResolver(a);
        let mainValueB = mainSortValueResolver(b);
        let secondaryValueA = secondarySortValueResolver(a);
        let secondaryValueB = secondarySortValueResolver(b);

        if (mainValueA == mainValueB) {
            return secondaryValueA < secondaryValueB ? -1 : (secondaryValueA > secondaryValueB) ? 1 : 0;
        } else {
            return mainValueA < mainValueB ? -1 : (mainValueA > mainValueB) ? 1 : 0;
        }
    }
}
