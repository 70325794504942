<input #input="ngModel"
       type="checkbox"
       id="{{name}}"
       class="k-checkbox"
       [required]="true"
       [(ngModel)]="value"
       [disabled]="disabled"
       (change)="manualChange()" />
<label for="{{name}}" class="k-checkbox-label">
    <fibaDisplayItemText *ngIf="!text" [itemCode]="itemCode" [displayAsSpan]="true" [itemCategoryCode]="itemCategoryCode"></fibaDisplayItemText>
    <span *ngIf="text" [innerHtml]="text"></span>
</label>
<div *ngIf="ngControl.errors" class="validation-errors">
    <ul>
        <li [hidden]="!ngControl.errors.required">
            {{label}} is required
        </li>
    </ul>
</div>