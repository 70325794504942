<label *ngIf="label && label.length > 0" for="{{name}}">{{label}}</label>
<div>
    <input #input="ngModel"
           (blur)="onTouched($event)"
           (change)="manualChange()"
           [(ngModel)]="value"
           [disabled]="disabled"
           [required]="required"
           id="{{name}}"
           type="email"
    />
    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
            <li [hidden]="!ngControl.errors.minlength">
                {{label}} must be at least TODO characters long.
            </li>
            <li [hidden]="!ngControl.errors.maxlength">
                {{label}} cannot be more than TODO characters long.
            </li>
            <li [hidden]="!ngControl.errors.fibaEmailFibaValidator">
                {{label}} email format is invalid.
            </li>
        </ul>
    </div>
</div>
