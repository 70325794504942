import { Component, EventEmitter, forwardRef, Injector, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputDateMonth',
    templateUrl: './fiba-input-date-month.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputDateMonthComponent),
            multi: true
        },
    ],
    host: {class: 'fiba-input'},
})
export class FibaInputDateMonthComponent extends FibaInputBase<Date> {

    @Input() public type = 'filter';
    @Output() public change:EventEmitter<Date> = new EventEmitter();

    constructor(protected injector:Injector) {
        super(injector);
    }

    public get value():Date {
        return <Date>this._value;
    }

    public set value(value:Date) {
        let val:Date;
        if (!value) {
            val = undefined;
        } else if (typeof value === 'string') { // Safety conversion
            if (value === '') {
                this._value = undefined;
                return;
            }
            const strVal:string = <string>value;
            if (strVal.charAt(4) === '-') {
                // TODO : check this and burn accordingly (check for risk of being local front user culture dependent)
                val = new Date(Number.parseInt(strVal.substring(0, 4), 10), Number.parseInt(strVal.substring(5, 7), 10) - 1, Number.parseInt(strVal.substring(8, 10), 10));
            } else {
                val = new Date(strVal);
            }
        } else {
            val = value;
            val.setHours(0, 0, 0, 0);
        }
        if (val) {
            val.setTime(val.getTime() - val.getTimezoneOffset() * 60000); // Add UTC offset to value, eg 02:00:00 UTC+2
        }
        if (val !== this._value) {
            this._value = val;
        }
    }

    public handleValue(value:Date) {
        this.value = value;
        this.onChange(this.value);
        this.change.emit(this.value);
        this.onTouched();
    }
}
