export enum Env {
    DEV,
    PROD,
}

export abstract class AppConfig {
    public readonly env:Env;
    // URLs and API endpoints
    readonly baseUrl:string;

    readonly agentTestApiEndpoint:string;
    readonly agentPreRegistrationApiEndpoint:string;
    readonly agentRegistrationApiEndpoint:string;
    readonly itemApiEndpoint:string;
    readonly countryApiEndpoint:string;
    readonly documentApiEndpoint:string;
    readonly paymentApiEndpoint:string;
    readonly organisationApiEndpoint:string;
    readonly organisationAutocompleteApiEndpoint:string;
    readonly technicalDelegateApiEndpoint:string;
    readonly tableOfficialApiEndpoint:string;
    readonly playerReleaseApiEndpoint:string;
    readonly gameOfficialApiEndpoint:string;
    readonly equipmentApiEndpoint: string;
    readonly equipmentPartnerApplicationApiEndpoint: string;
}
