import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@app/app.config';

import { HttpService } from '../utils/http.service';
import { TechnicalDelegatePublicConfirmationDTO } from '@fiba/models/dtos/generated/technical-delegate-public-confirmation-dto';

@Injectable()
export class TechnicalDelegateConfirmationDataService {
    protected technicalDelegateApiEndpoint:string;

    constructor(protected http:HttpService, protected config:AppConfig) {
        this.technicalDelegateApiEndpoint = config.technicalDelegateApiEndpoint;
    }

    public getFetchObservable(guid:string):Observable<TechnicalDelegatePublicConfirmationDTO> {
        return this.http.get(this.technicalDelegateApiEndpoint + "/license-confirmation/" + guid);
    }

    public getPutObservable(model:TechnicalDelegatePublicConfirmationDTO):Observable<boolean> {
        return this.http.put(this.technicalDelegateApiEndpoint + "/license-confirmation/" + model.guid, model);
    }

}
