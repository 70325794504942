export class DocumentGenericTypes {
    public  static readonly PERSON = 'Person';
    public   static readonly NATIONALITY = 'Nationality Identification';
    public    static readonly TRANSFER = 'Transfer';
    public  static readonly PLAYER_LICENSE = 'Player License';
    public static readonly PLAYER_RELEASE = 'Player Release';
    public static readonly BASKETBALL_LICENSE = 'Basketball License';
    public  static readonly LICENSE_REQUEST = 'License Request';
    public  static readonly SANCTION_APPLICATION = 'Sanction Application';
    public   static readonly VENUE = 'Venue';
    public static readonly ORGANISATION = 'Organisation';
    public  static readonly LONG_LIST_MEMBER = 'LongListMember';
    public  static readonly COMPETITION_TEAM = 'Competition Team';
    public static readonly TEAM_REGISTRATION_DOCUMENT = 'Team Registration Document';
    public static readonly OFFICIAL_LICENSE_PERIOD = 'Official License Period Document';
    public static readonly AGENT_REGISTRATION = 'Agent Registration';

}
