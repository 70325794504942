<input #input="ngModel"
       (change)="manualChange()"
       [(ngModel)]="value"
       [disabled]="disabled"
       [id]="option + name"
       [value]="option"
       class="k-radio"
       name="{{name}}"
       type="radio"/>
<label *ngIf="label && label.length > 0" [for]="option + name"
       class="k-radio-label">{{label}}</label>
