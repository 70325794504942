import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@app/app.config';
import { OrganisationListItemDTO, OrganisationFilters, ApiListResponse, ThingDTO, OrganisationCategories } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import { OrganisationPublicDTO } from '@fiba/models/dtos/generated/organisation-public-dto';

@Injectable()
export class OrganisationDataService {
    protected organisationApiEndpoint: string;
    protected organisationAutocompleteApiEndpoint: string;
    protected organisationStatusApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig) {
        this.organisationApiEndpoint = config.organisationApiEndpoint;
        this.organisationAutocompleteApiEndpoint = config.organisationAutocompleteApiEndpoint;
    }

    fetchList(filters: OrganisationFilters): Observable<ApiListResponse<OrganisationListItemDTO>> {
        return this.http.get(this.organisationApiEndpoint, filters.getSearchParams());
    }

    //fetchOrganisation(organisationId: number): Observable<OrganisationPublicDTO> {
    //    return this.http.get(`${this.organisationApiEndpoint}/${organisationId}`);
    //}

    fetchOrganisationForPicker(organisationId: number): Observable<OrganisationPublicDTO> {
        return this.http.get(`${this.organisationApiEndpoint}/${organisationId}/organisation-picker`);
    }

    //fetchAutoCompleteList(name: string = '', organisationCategories: Array<string>, displayNFWithCountryName: boolean = false, displayLeagueWithOrganisationName: boolean = false, excludedOrganisationId: number = undefined): Observable<ThingDTO[]> {
    //    let params = {
    //        'name': name
    //        , 'displayNFWithCountryName': String(displayNFWithCountryName)
    //        , 'displayLeagueWithOrganisationName': String(displayLeagueWithOrganisationName)
    //    };
    //    if (organisationCategories && organisationCategories.length > 0) {
    //        params['organisationCategoryCodes'] = organisationCategories;
    //    }
    //    if (excludedOrganisationId) {
    //        params['excludedOrganisationId'] = String(excludedOrganisationId);
    //    }
    //    return this.http.get(this.organisationAutocompleteApiEndpoint, params);
    //}

}
