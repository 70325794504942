import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FormDocumentDTO, ItemDTO, ConfigurationDocumentDTO } from '@fiba/models';

import { HttpService, IFileData } from '../utils/http.service';
import { AppConfig } from '@app/app.config';

@Injectable()
export class DocumentDataService {

    protected apiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.apiEndpoint = config.documentApiEndpoint;
    }

    public getMyFileFromBackend(documentId: number, pwc: boolean = false): Observable<IFileData> {
        return this.http.getFileData(`${this.apiEndpoint}/${documentId}?pwc=${pwc}`);
    }

    public editDocument(documentId: number, documentTmpId: number): Observable<FormDocumentDTO> {
        return this.http.put(`${this.apiEndpoint}/${documentId}/edit-document-from/${documentTmpId}`, null);
    }

    public updloadDocument(files: File[], title: string, item: ItemDTO, entityType: string, entityId: number): Observable<FormDocumentDTO> {
        const formData: FormData = new FormData();

        formData.append('documentId', undefined);
        formData.append('entityType', entityType);
        formData.append('entityDocumentType', null);
        formData.append('entityId', String(entityId));
        formData.append('title', title);
        formData.append('typeItemId', String(item.itemId));

        for (const file of files) {
            formData.append('files', file, file.name);
        }

        return this.http.postForm(`${this.apiEndpoint}`, formData);
    }

    public fetchDocumentConfiguration(itemDocumentTypeCode: string): Observable<ConfigurationDocumentDTO[]> {
        return this.http.get(`${this.apiEndpoint}/${itemDocumentTypeCode}/configurations`);
    }

    public downloadAllDocuments(entityId: number): Observable<IFileData> {
        throw new Error('This method must be implement in the child service.');
    }
}
