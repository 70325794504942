import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { LocalStorage } from '@fiba/utils/local-storage.service';
import { NotificationService } from '@fiba/utils/notification.service';
import 'reflect-metadata';
import 'zone.js';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
    protected subscriptions:Subscription[];

    constructor(notificationService:NotificationService) {
        this.subscriptions = [];

        // Store URL in local storage for redirection after login
        if (window.location.pathname.indexOf('auth-handler') === -1) {
            LocalStorage.setItem('redirectUrl', window.location.pathname);
        }

        // Display version number
        document.getElementById('version-number').style.display = 'block';

    }

    public ngOnDestroy():void {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }
}
