import { Component, OnInit, Input, Output, Injector, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormControl, AbstractControl } from '@angular/forms';

import { DocumentGenericDTO, ItemDTO, ItemCategoryCodes } from '@fiba/models';
import { FibaPopupBase } from '@fiba/forms';

@Component({
    selector: 'fibaPopupDocumentReject',
    templateUrl: './fiba-popup-document-reject.component.html',
})
export class FibaPopupDocumentRejectComponent extends FibaPopupBase<DocumentGenericDTO>{
    private readonly ItemCategoryCodes_PlayerLicenseRejectReason = ItemCategoryCodes.PlayerLicenseRejectReason;
    public readonly presets: string[] = ['Wrong document', 'Wrong person', 'Unreadable', 'Technical error', 'Other']
    public preset: string;
    constructor(protected injector: Injector) {
        super(injector);
    }

    public onSave(e): void {
        super.onSave(e);
    }

    public onCancel(event): void {
        super.onCancel(event);
    }

    private setControlErrors(controlName: string, errors: any): void {
        const control: AbstractControl = this.ngForm.form.controls[controlName];
        if (control) {
            control.setErrors(errors);
        }
    }
    public changePreset() {
        if (this._model) {
            this._model.reasonOfReject = this.preset;
        }
    }
}