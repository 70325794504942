import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';

import { Logger } from '@fiba/utils/logger';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

const APP_VERSION_HEADER_KEY = 'X-FIBA-MAP-Version';

let lastAppVersion: string;

export interface IFileData {
    blob: Blob;
    name: string;
}

export enum IHttpClientResponseType {
    array = 'arraybuffer',
    blob = 'blob',
    json = 'json',
    text = 'text',
}

export interface IHttpClientRequestOptions {
    headers?: HttpHeaders;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' | any;
    // observe?: 'body' | 'response';
    reportProgress?: boolean;
    withCredentials?: boolean;
}

@Injectable()
export class HttpService {

    constructor(protected http: HttpClient) {

    }

    public static extractFormErrorMsgsCb(response: HttpResponse<string> | HttpErrorResponse): Observable<any> {

        function isNotHttpErrorResponse(response: HttpResponse<string> | HttpErrorResponse): response is HttpResponse<string> {
            return ((response as HttpErrorResponse).error == undefined);
        }

        function getErrorMessage(errors: any): any[] {
            let errorMsgs = [];
            if (errors) {
                switch (typeof (errors)) {
                    case 'string':
                        errorMsgs.push(errors);
                        break;
                    case 'object':
                        for (const key of Object.keys(errors)) {
                            if (key !== 'stackTrace') {
                                errorMsgs.push(getErrorMessage(errors[key]));
                            }

                        }
                        break;
                    case 'boolean':
                        break;
                    default: {
                        errorMsgs.push('This error mesage should not appear, report it as a bug and specify how to recreate it properly');
                        Logger.info('Add a case for:', typeof (errors));
                        break;
                    }
                }
            }
            return errorMsgs;

        }

        return Observable.create((observer: Observer<any>) => {
            let errorMsgs = [];
            let errors;

            if (isNotHttpErrorResponse(response) && response.body) {
                const body = response.body;

                if (body.length > 0) {
                    try {
                        errors = JSON.parse(body);
                        errorMsgs = [];
                        if (response.status === 400) {
                            for (const key in errors) {
                                errorMsgs.push(errors[key][0]);
                            }
                            Logger.warn('Bad request error from backend', errors);
                        } else {
                            errorMsgs = [errors.message + errors.exceptionMessage ? ': ' + errors.exceptionMessage : ''];
                            Logger.error('Error from backend', errors);
                        }

                    } catch (e) {
                        Logger.error('Error from backend', body);
                        errorMsgs = [body];
                    }
                } else {
                    Logger.error('Error from backend', response);
                    errorMsgs = [response.status + ': ' + response.statusText];
                }
            } else {
                if ((response as HttpErrorResponse).error) {
                    errors = (response as HttpErrorResponse).error;
                    errorMsgs = getErrorMessage(errors);
                } else {
                    if (response.statusText) {
                        errorMsgs.push(response.statusText);
                    } else {
                        errorMsgs.push('This error message should not appear, report it as a bug and specify how to recreate it properly');
                    }
                }
            }

            observer.error(errorMsgs);
        });

    }

    public static extractErrorMsgCb(response: string): Observable<any> {
        return of(`Error from backend: ${response}`);
    }

    protected static toURLSearchParams(params: { [key: string]: string | string[] }): HttpParams {
        let httpParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const value = params[key];
                if (Array.isArray(value)) {
                    for (const innerValue of value) {
                        httpParams = httpParams.append(key, innerValue);
                    }
                } else {
                    httpParams = httpParams.append(key, value);
                }
            }
        }
        return httpParams;
    }

    protected static checkAppVersion(response: HttpResponse<string>): void { // TODO: use our own LocalStorage service
        const currentAppVersion = response.headers.get(APP_VERSION_HEADER_KEY);

        if (!lastAppVersion) {
            lastAppVersion = currentAppVersion;
        }

        // FIXME: Check if version > current and not !==
        if (currentAppVersion !== lastAppVersion) {
            location.reload();
        }
    }

    public get<T>(url: string, params?: { [key: string]: string | string[] }): Observable<T> {
        return this._get(url, params);
    }

    public getFileData(url: string, params?: { [key: string]: string | string[] }, defaultName?: string): Observable<IFileData> {
        return this.getWithFileName(url, params, IHttpClientResponseType.blob).pipe(
            map((res) => {
                return {
                    blob: (res as HttpResponse<any>).body,
                    name: res.name || defaultName,
                };
            }));
    }

    public post<T>(url: string, data: any, params?: { [key: string]: string | string[] }): Observable<T> {
        const req: IHttpClientRequestOptions = this.getHeaders();
        req.headers = req.headers.append('content-type', 'application/json');
        if (params) {
            req.params = HttpService.toURLSearchParams(params);
        }

        const body = JSON.stringify(data);

        return this.http.post<T>(url, body, req)
            .pipe(catchError(HttpService.extractFormErrorMsgsCb))
            ;
    }

    public postForm<T>(url: string, formData: FormData): Observable<T> {
        const req = this.getHeaders();
        req.headers = req.headers.append('Access-Control-Allow-Origin', '*');

        return this.http.post<T>(url, formData, req).pipe(catchError(HttpService.extractFormErrorMsgsCb))
    }

    public put<T>(url: string, data: any = null): Observable<T> {
        const req = this.getHeaders();
        req.headers = req.headers.append('content-type', 'application/json');

        const body = JSON.stringify(data);

        return this.http.put(url, body, req).pipe(catchError(HttpService.extractFormErrorMsgsCb));
    }

    public delete(url: string): Observable<any> {
        const req = this.getHeaders();

        return this.http.delete(url, req).pipe(catchError(HttpService.extractFormErrorMsgsCb));
    }

    public getHeaders() {
        let headers: HttpHeaders = new HttpHeaders();
        return { headers };
    }

    protected buildOptions(params?: { [key: string]: string | string[] },
        responseType?: IHttpClientResponseType): IHttpClientRequestOptions {

        const req: IHttpClientRequestOptions = this.getHeaders();

        if (params) {
            req.params = HttpService.toURLSearchParams(params);
        }
        if (responseType != undefined) {
            req.responseType = responseType;
        }

        // https://github.com/angular/angular/issues/18586
        const options = {
            ...req,
            observe: 'response' as 'body',
        };

        return options;
    }

    protected _get<T>(url: string,
        params?: { [key: string]: string | string[] },
        responseType?: IHttpClientResponseType): Observable<T> {
        return this.http.get(url, this.buildOptions(params, responseType))
            .pipe(
                // the following line stinks, it should be tap((res)=>{HttpService.checkAppVersion(res)})
                tap(HttpService.checkAppVersion),
                map((res: any) => {
                    return res.body as T;
                }),
                catchError(HttpService.extractFormErrorMsgsCb)
            )
    }

    protected getWithFileName(url: string,
        params?: { [key: string]: string | string[] },
        responseType?: IHttpClientResponseType): Observable<any> {
        return this.http.post(url, params, this.buildOptions(null, responseType)).pipe(
            tap(HttpService.checkAppVersion),
            map((res: any) => {
                res.name = this.getFileNameFromHttpResponse(res);
                return res;
            }),
            catchError(HttpService.extractFormErrorMsgsCb)
        )
    }

    protected getFileNameFromHttpResponse(httpResponse: any): string {
        const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
        if (contentDispositionHeader) {
            const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
            return result.replace(/"/g, '');
        }
        return undefined;
    }
}
