import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactValidationPublicConfirmationDTO, ItemDTO } from '@fiba/models';

import { AppConfig } from '@app/app.config';
import { HttpService } from '../utils/http.service';
import { EquipmentPartnerApplicationFormDTO } from '../../models/dtos/generated/equipment-partner-application-form-dto';
import { EquipmentPartnerApplicationConfirmationDTO } from '../../models/dtos/generated/equipment-partner-application-confirmation-dto';

@Injectable()
export class EquipmentApplicationDataService {
    protected equipmentApiEndpoint: string;
    protected equipmentPartnerApplicationApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig) {
        this.equipmentApiEndpoint = config.equipmentApiEndpoint;
        this.equipmentPartnerApplicationApiEndpoint = config.equipmentPartnerApplicationApiEndpoint;
        
    }

    public createAccount(model: EquipmentPartnerApplicationFormDTO): Observable<boolean> {
        return this.http.post(this.equipmentPartnerApplicationApiEndpoint, model);
    }

    public getFetchObservable(guid: string): Observable<EquipmentPartnerApplicationConfirmationDTO> {
        return this.http.get(this.equipmentPartnerApplicationApiEndpoint + "/confirmation/" + guid);
    }

    public getPutObservable(model: EquipmentPartnerApplicationConfirmationDTO): Observable<any> {
        return this.http.put(this.equipmentPartnerApplicationApiEndpoint + "/confirmation", model);
    }

    public getMapUrlObservable(): Observable<any> {
        return this.http.get(this.equipmentPartnerApplicationApiEndpoint + "/map-url");
    }

}

