// For ease of use, this class is not intended to be injected as a standard ng service
export class Logger {
    public static readonly ALL = 999;
    public static readonly DEBUG = 40;
    public static readonly INFO = 30;
    public static readonly WARNING = 20;
    public static readonly ERROR = 10;
    public static readonly NONE = 0;

    private static logLevel = Logger.ALL;

    public static get debug():(...params:any[]) => void {
        if (Logger.logLevel >= Logger.DEBUG) {
            return console.debug.bind(console);
        }
        return () => {
        };
    }

    public static get info():(...params:any[]) => void {
        if (Logger.logLevel >= Logger.INFO) {
            return console.info.bind(console);
        }
        return () => {
        };
    }

    public static get warn():(...params:any[]) => void {
        if (Logger.logLevel >= Logger.WARNING) {
            return console.warn.bind(console);
        }
        return () => {
        };
    }

    public static get error():(...params:any[]) => void {
        if (Logger.logLevel >= Logger.ERROR) {
            return console.error.bind(console);
        }
        return () => {
        };
    }

    public static setLogLevel(logLevel:number):void {
        Logger.logLevel = logLevel;
    }

    public static getLogLevel():number {
        return Logger.logLevel;
    }
}
