import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { DataResult, GroupDescriptor, process } from '@progress/kendo-data-query';

import { DocumentDataService } from '@fiba/data-services';
import { FibaEFGridBase } from '@fiba/forms';
import { DocumentGenericDTO, DocumentGenericTypes } from '@fiba/models';
import { Logger } from '@fiba/utils/logger';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';

@Component({
    selector: 'fibaGridDocuments',
    templateUrl: './fiba-grid-documents.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaGridDocumentsComponent),
            multi: true,
        },
    ],
    host: { class: 'fiba-input' },
})
export class FibaGridDocumentsComponent extends FibaEFGridBase<DocumentGenericDTO> {
    protected readonly DocumentGenericTypes_NATIONALITY = DocumentGenericTypes.NATIONALITY;

    @Input() public entityId: number;
    @Input() public categoryCode: string;
    @Input() public parentCode: string;
    @Input() public documentType: string;
    @Input() public entityType: string;
    @Input() public oneDocument: boolean = false;
    @Input() public entityTypeBase: string = null;
    @Input() public entityIdBase: number = null;
    @Input() public grouped: boolean = true;
    @Input() public editable: boolean = true;
    @Input() public showComment: boolean = false;

    public saveMethod: string = 'POST';
    public documentName: string = null;
    public originalDocumentId: number = 0;

    constructor(
        protected notificationService: NotificationService,
        protected documentDataService: DocumentDataService) {
        super();
    }

    public groups: GroupDescriptor[] = [{ field: 'documentType' }];
    public documentGrouped: DataResult;
    public originalDocumentDMSId: string = null;

    // Grid document editing
    public addHandler($event): void {
        super.addHandler($event);
        this.saveMethod = 'POST';
        this.documentName = null;
        this.originalDocumentId = 0;
        this.originalDocumentDMSId = null;
    }

    public editHandler($event): void {
        super.editHandler($event);
        this.saveMethod = 'POST';
        this.originalDocumentId = this.editDataItem.documentId;
        this.originalDocumentDMSId = this.editDataItem.documentDmsid;
        this.documentName = this.editDataItem.fileName;
    }

    public saveHandler(pd: DocumentGenericDTO): void {
        if (this.originalDocumentId != 0 && this.originalDocumentId != this.editDataItem.documentId) {
            this.documentDataService.editDocument(this.originalDocumentId, this.editDataItem.documentId)
                .subscribe(
                    null,
                    (error) => {
                        Logger.error(error);
                        this.notificationService.emitNotification(NotificationType.Error, error);
                    });

            pd.documentId = this.originalDocumentId;
            pd.documentDmsid = this.originalDocumentDMSId;
        }

        super.saveHandler(pd);
        this.loadGroup();
    }

    protected equalDocuments(doc1: DocumentGenericDTO, doc2: DocumentGenericDTO): boolean {
        if (doc1 && doc2) {
            return doc1.documentId === doc2.documentId;
        }
        return false;
    }

    protected downloadHandler(formDocumentGuid: string, pwc: boolean = false) { };

    protected loadGroup(): void {
        if (this._model) {
            this.documentGrouped = process(this._model_filtered, { group: this.groups });
        }
    }

    public refreshFilteredData(): void {
        this._model_filtered = [];
        for (const i in this._model) {
            const doc: DocumentGenericDTO = this._model[i];
            doc['sourceRowIndex'] = i;
            this._model_filtered.push(doc);
        }
        this.loadGroup();
    }
}
