import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';

import { HttpService } from '../utils/http.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class DataCachedService<T> {
    protected cache: Map<string, T[] | T>;

    constructor(protected http: HttpService) {
        this.cache = new Map<string, T[] | T>();
    }

    protected fetchAjaxEntities(url: string, params?: { [key: string]: string | string[] }): Observable<T[]> {
        return this.http.get(url, params);
    }

    protected fetchAjaxEntity(url: string, params?: { [key: string]: string | string[] }): Observable<T> {
        return this.http.get(url, params);
    }

    public clearCache(): void {
        this.cache.clear();
    }

    public fetchEntities(url: string, cacheKey: string = '', params?: { [key: string]: string | string[] }): Observable<T[]> {
        cacheKey = url + cacheKey + JSON.stringify(params || ''); // be sure to parameterize the cache key
        const items = this.cache.get(cacheKey);
        if (items != undefined) {
            return of(items as T[]);
        } else {
            return this.fetchAjaxEntities(url, params).pipe(tap(
                (data) => {
                    this.cache.set(cacheKey, data);
                },
            ));
        }
    }

    public fetchEntity(url: string, cacheKey: string = '', params?: { [key: string]: string | string[] }): Observable<T> {
        cacheKey = url + cacheKey + JSON.stringify(params || ''); // be sure to parameterize the cache key
        const item = this.cache.get(cacheKey);
        if (item != undefined) {
            return of(item as T);
        } else {
            return this.fetchAjaxEntity(url, params).pipe(tap(
                (data) => {
                    this.cache.set(cacheKey, data);
                },
            ));
        }
    }
}
