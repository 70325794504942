import { Directive, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { RowArgs } from '@progress/kendo-angular-grid';

@Directive()
export abstract class FibaGridBase<T> implements ControlValueAccessor {
    public isNew:boolean = false;
    @Input() public disabled:boolean = false;
    @Input() public label:string;
    public mySelection:number[] = [];
    // Grid editing
    public editDataItem:T;
    @Input() protected required:boolean;
    protected editRowIndex:number;

    public _model:T[];

    public get model() {
        return this._model;
    }

    public set model(model:T[]) {
        this._model = model;
        this.onModelChanged(); // As of now (TS 2.3), while targetting ES5, we cannot use super.model while in an overridden getter/setter. Override this method instead
    }

    public mySelectionKey(context:RowArgs):number {
        return context.index;
    }

    public writeValue(model:T[]) {
        this.model = model;
    }

    // TODO - use just a definition here
    public onChange = (_:any) => {
    };
    public onTouched:any = () => {
    };

    public registerOnChange(fn):void {
        this.onChange = fn;
    }

    public registerOnTouched(fn:any):void {
        this.onTouched = fn;
    }

    public addHandler($event):void {
        if (!this._model) {
            this._model = [];
        }
        this.editDataItem = this.newModel();
        this.isNew = true;
        this.editRowIndex = this._model.length;
    }

    public editHandler($event):void {
        const {dataItem, rowIndex} = $event;
        this.editDataItem = JSON.parse(JSON.stringify(dataItem));
        this.isNew = false;
        this.editRowIndex = rowIndex;
    }

    public cancelHandler():void {
        this.editDataItem = undefined;
    }

    public saveHandler(pn:T):void {
        if (this.isNew) {
            this._model.push(pn);
        } else {
            this._model[this.editRowIndex] = pn;
        }
        this.editDataItem = undefined;
        this.onChange(this._model);
    }

    public removeHandler({rowIndex}):void {
        this._model.splice(rowIndex, 1);
        this.onChange(this._model);
    }

    public setDisabledState(isDisabled:boolean):void {
        this.disabled = isDisabled;
    }

    protected onModelChanged():void {
        this.loadGroup();
    }

    protected loadGroup():void {
    }

    protected newModel():T {
        return {} as T;
    }
}
