import { ModuleWithProviders, NgModule } from '@angular/core';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { UploadModule } from '@progress/kendo-angular-upload';

@NgModule({
    imports: [
        GridModule,
        ButtonsModule,
        DropDownsModule,
        DialogModule,
        UploadModule,
        PopupModule,
        InputsModule,
        DateInputsModule,
        LayoutModule,
    ],
    exports: [
        GridModule,
        ButtonsModule,
        DropDownsModule,
        DialogModule,
        UploadModule,
        PopupModule,
        InputsModule,
        DateInputsModule,
        LayoutModule,
    ],
    entryComponents: [],
})

export class KendoModule {
    public static forRoot():ModuleWithProviders<KendoModule> {
        return {
            ngModule: KendoModule,
            providers: [
                DialogService,
            ],
        };
    }
}
