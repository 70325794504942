import { Directive, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive()
export abstract class FibaInputBase<T> implements ControlValueAccessor, OnInit {
    @Input() public label:string; // Text to display in the label
    @Input() public name:string; // HTML id and name to give to the input
    @Input() public required:boolean;
    @Input() public disabled:boolean;
    @Input() public contextCode:string;
    public ngControl:NgControl;

    constructor(protected injector:Injector) {
    }

    protected _value:T | number; // Value to bind to the input

    public get value() {
        return this._value;
    }

    public set value(value:T | number) {
        let val:T | number = value;
        if (typeof val === 'undefined') {
            val = null;
        }
        if (val !== this._value) {
            this._value = val;
            this.valueChanged(val);
        }
    }

    public ngOnInit():void {
        try {
            this.ngControl = this.injector.get(NgControl);
        } catch (e) {
            throw new Error('ngModel missing on fiba custom input');
        }
    }

    public writeValue(value:T | number):void {
        this.value = value;
    }

    public onChange = (_:any) => {
    };
    public onTouched:any = () => {
    };

    public registerOnChange(fn):void {
        this.onChange = fn;
    }

    public registerOnTouched(fn:any):void {
        this.onTouched = fn;
    }

    public manualChange():void { // called from templates when the user changes the value of an input manually (text input, selection change...)
        this.onChange(this.value);
    }

    public setDisabledState(isDisabled:boolean):void {
        this.disabled = isDisabled;
    }

    public valueChanged(val):void {
    }

}
