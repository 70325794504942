import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DocumentTypes } from '@fiba/models';
import { AppConfig } from '@app/app.config';

import { DocumentStatusDataService } from '../common/document-status-data.service';
import { HttpService, IFileData } from '../utils/http.service';

@Injectable()
export class AgentRegistrationDocumentStatusDataService extends DocumentStatusDataService {

    private readonly agentRegistrationApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        super(http, config);
        this.agentRegistrationApiEndpoint = 'BAD_ENDPOINT'; // TODO : replace by something from config
    }

    public get ItemDocumentTypeCode(): string {
        return DocumentTypes.AgentRegistrationDocument;
    }

    /**
     * Download all documents linked to the current entityId.
     * @param entityId
     */
    public downloadAllDocuments(entityId: number): Observable<IFileData> {
        return this.http.getFileData(`${this.agentRegistrationApiEndpoint}/${entityId}/download-all-documents`, null, 'allDocumentsTransferRequest.zip');
    }
}
