import { Directive, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { RowArgs } from '@progress/kendo-angular-grid';

/**
 * Editable and Filterable (client-side only) grid
 */
@Directive()
export abstract class FibaEFGridBase<T> implements ControlValueAccessor {
    public isNew:boolean = false;
    @Input() public disabled:boolean;
    @Input() public label:string;
    public _model_filtered:T[];
    // Grid editing
    public editDataItem:T;
    protected mySelection:number[] = [];
    protected editRowId:number;

    public _model:T[];

    public get model() {
        return this._model;
    }

    public set model(model:T[]) {
        this._model = model;
        this.onModelChanged(); // As of now (TS 2.3), while targetting ES5, we cannot use super.model while in an overridden getter/setter. Override this method instead
    }

    public mySelectionKey(context:RowArgs):number {
        return context.index;
    }

    public writeValue(value:T[]) {
        this.model = value;
        this.refreshFilteredData();
    }

    public onChange = (_:any) => {
    };

    public onTouched:any = () => {
    };

    public registerOnChange(fn) {
        this.onChange = fn;
    }

    public registerOnTouched(fn:any) {
        this.onTouched = fn;
    }

    public addHandler($event) {
        if (!this._model) {
            this._model = [];
        }
        this.editDataItem = this.newModel();
        this.isNew = true;
        this.editRowId = this._model_filtered.length;
    }

    public editHandler($event) {
        const {dataItem, rowIndex} = $event;
        this.editDataItem = JSON.parse(JSON.stringify(dataItem));
        this.isNew = false;
        this.editRowId = this.editDataItem['sourceRowIndex'];
    }

    public cancelHandler() {
        this.editDataItem = undefined;
    }

    public saveHandler(row:T) {
        if (this.isNew) {
            this._model.push(row);
        } else {
            const sourceRowIndex:number = this._model.findIndex((item) => item['sourceRowIndex'] === this.editRowId);
            this._model[sourceRowIndex] = row;
        }
        this.editDataItem = undefined;
        this.onChange(this._model);
        this.refreshFilteredData();
    }

    public removeHandler($event) {
        const {dataItem, rowIndex} = $event;
        const editDataItem = JSON.parse(JSON.stringify(dataItem));
        const editRowId = editDataItem['sourceRowIndex'];

        const sourceRowIndex:number = this._model.findIndex((item) => item['sourceRowIndex'] === editRowId);
        this._model.splice(sourceRowIndex, 1);
        this.onChange(this._model);

        this.refreshFilteredData();
    }

    public setDisabledState(isDisabled:boolean) {
        this.disabled = isDisabled;
    }

    protected onModelChanged():void {
        this.loadGroup();
        this.sortGroup();
    }

    protected loadGroup() {
    }

    protected sortGroup() {
    }

    protected newModel():T {
        return {} as T;
    }

    protected abstract refreshFilteredData():void;
}
