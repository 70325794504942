import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[fibaUrlValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => FibaUrlValidator), multi: true}
    ]
})
export class FibaUrlValidator implements Validator {

    protected pattern = /^(ftp|http|https):\/\/[^ "]+$/;

    validate(c:AbstractControl):{ [key:string]:any } {
        // self value
        let v = c.value;

        if (!this.pattern.test(v) && v != undefined && v != '') {
            c.setErrors({fibaUrlValidator: true});
            return {
                fibaUrlFibaValidator: true
            }
        } else {
            if (c.errors) {
                delete c.errors['fibaUrlValidator'];
                if (!Object.keys(c.errors).length) {
                    c.setErrors(null);
                }
            }
        }

        return null;
    }
}
