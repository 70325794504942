import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@app/app.config';
import { HttpService } from '../utils/http.service';
import { PaymentTermsAndConditionsDTO, PaymentInitializeDTO, PaymentReceiptDTO, PaymentForwardDTO } from '@fiba/models';


@Injectable()
export class PaymentDataService {    
    protected paymentApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig) {
        this.paymentApiEndpoint = config.paymentApiEndpoint;
    }

    public initializePayment(paymentKey: string): Observable<PaymentInitializeDTO> {
        return this.http.put(`${this.paymentApiEndpoint}/${paymentKey}`);
    }

    public assertPayment(paymentKey: string, state: string): Observable<PaymentReceiptDTO> {
        return this.http.put(`${this.paymentApiEndpoint}/${paymentKey}/${state}`);
    }

    public fetchPaymentTermsAndConditions(paymentKey: string): Observable<PaymentTermsAndConditionsDTO> {
        return this.http.get(`${this.paymentApiEndpoint}/${paymentKey}/terms-and-conditions`);
    }
    public receiptPayment(paymentKey: any): Observable<PaymentReceiptDTO> {
        return this.http.get(`${this.paymentApiEndpoint}/${paymentKey}/receipt`);
    }

    public forwardPayment(paymentKey: string, model: PaymentForwardDTO): Observable<any> {
        return this.http.put(`${this.paymentApiEndpoint}/${paymentKey}/forward`, model)
    }
}
