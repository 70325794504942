import { Component, forwardRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputUrl',
    templateUrl: './fiba-input-url.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputUrlComponent),
            multi: true,
        },
    ],
    host: {class: 'fiba-input'},
})
export class FibaInputUrlComponent extends FibaInputBase<string> {
    constructor(protected injector:Injector) {
        super(injector);
    }
}
