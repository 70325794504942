import { Component, Input, } from '@angular/core';
import { Observable } from 'rxjs';

import { ItemDTO } from '@fiba/models';
import { ItemDataService } from '@fiba/data-services';

@Component({
    selector: 'fibaDisplayItemText',
    templateUrl: './fiba-display-item-text.component.html',
})
export class FibaDisplayItemTextComponent {
    @Input() public displayAsSpan: boolean = false;
    @Input() protected set itemCode(value: string) {
        this._itemCode = value;
        this.getItem();
    };
    @Input() protected set itemCategoryCode(value: string) {
        this._itemCategoryCode = value;
        this.getItem();
    };


    protected _itemCode: string;
    protected _itemCategoryCode: string;
    public text;

    constructor(protected dataService: ItemDataService) {
    }


    protected getItem() {
        if (this._itemCategoryCode && this._itemCode) {
            this.getObservable().subscribe(
                (res) => {
                    this.text = res ? res.description : "";
                },
                (error) => {
                });
        }
    }

    getObservable(): Observable<ItemDTO> {
        return this.dataService.fetchItem(this._itemCategoryCode, this._itemCode);
    }
}
