import { Injectable } from '@angular/core';

@Injectable()
export class SimpleStorageService { // extending Map does not seem possible
    protected values:Map<string, any>;

    constructor() {
        this.values = new Map<string, any>();
    }

    get(key:string):any {
        return this.values.get(key);
    }

    set(key:string, value:any):SimpleStorageService {
        this.values.set(key, value);
        return this;
    }

    clear():void {
        this.values.clear();
    }

    delete(key:string):void {
        this.values.delete(key);
    }
}
