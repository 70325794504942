/*export interface StorageContainer {
    length(): number;
    key(i: number): string;
    getItem(keyName: string): any;
    setItem(keyName: string, value: any): void;
    removeItem(keyName: string): void;
    clear(): void;
}*/

/*@Injectable()
export class LocalStorage {
    length(): number {
        return Number.NaN;
    }
    key(i: number): string {
        return null;
    }
    getItem(keyName: string): any {
        return null;
    }
    setItem(keyName: string, value: any): void { }
    removeItem(keyName: string): void { }
    clear(): void { }
}*/

/*@Injectable()
export class LocalStorageImpl extends LocalStorage {
    length(): number {
        return localStorage.length;
    }

    key(i: number): string {
        return localStorage.key(i);
    }

    getItem(keyName: string): any {
        return JSON.parse(localStorage.getItem(keyName));
    }

    setItem(keyName: string, value: any): void {
        localStorage.setItem(keyName, JSON.stringify(value));
    }

    removeItem(keyName: string): void {
        localStorage.removeItem(keyName);
    }

    clear(): void {
        localStorage.clear();
    }
}*/

// TODO: properly use DI
export class LocalStorage {
    static size():number {
        if (localStorage === null) {
            return 0;
        }
        return localStorage.length;
    }

    static key(i:number):string {
        if (localStorage === null) {
            return null;
        }
        return localStorage.key(i);
    }

    static getItem(keyName:string):any {
        if (localStorage === null) {
            return null;
        }
        return JSON.parse(localStorage.getItem(keyName));
    }

    static setItem(keyName:string, value:any):void {
        if (localStorage === null) {
            return;
        }
        localStorage.setItem(keyName, JSON.stringify(value));
    }

    static removeItem(keyName:string):void {
        if (localStorage === null) {
            return;
        }
        localStorage.removeItem(keyName);
    }

    static clear():void {
        if (localStorage === null) {
            return;
        }
        localStorage.clear();
    }
}
