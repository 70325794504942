import { Directive, Input } from '@angular/core';
import { ControlValueAccessor, } from '@angular/forms';

/**
 * FibaMultiInputBaseComponent used for multiselect inputs
 */
@Directive()
export abstract class FibaMultiInputBaseComponent implements ControlValueAccessor {
    @Input() public label:string; // Text to display in the label
    @Input() protected name:string; // HTML id and name to give to the input
    @Input() protected required:boolean;
    @Input() protected disabled:boolean;
    protected _value:any[]; // Value to bind to the input

    /**
     * Gets the property value
     * @returns The value
     */
    get value() {
        return this._value;
    }

    /**
     * Sets the property value
     * @param {any[]} value
     */
    set value(value:any[]) {
        let val:any[] = value;
        if (typeof val === 'undefined') {
            val = [];
        }
        if (val !== this._value) {
            this._value = val;
            this.onChange(this._value);
        }
    }

    /**
     * Writes the property value
     * @param {any[]} value
     */
    public writeValue(value:any[]) {
        if (value) {
            this.value = value;
        }
    }

    public onChange = (_:any) => {
    };
    public onTouched:any = () => {
    };

    public registerOnChange(fn) {
        this.onChange = fn;
    }

    public registerOnTouched(fn:any) {
        this.onTouched = fn;
    }

    public onBlur() {
        this.onTouched();
    }
}
