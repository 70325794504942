import { Directive, EventEmitter, Injector, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { FibaInputBase } from './fiba-input-base';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Directive()
export abstract class FibaAutocompleteBaseComponent<T> extends FibaInputBase<T> {
    public _textField:string;
    public _valueField:string;
    @Input() public placeholder:string;
    @Input() delay:number = 300;
    @Output() change:EventEmitter<any> = new EventEmitter();
    protected _data:Observable<T[]>;
    protected searchValue:string;
    protected searchTerms = new Subject<string>();
    @Input() protected type:string = 'filter';

    constructor(protected injector:Injector) {
        super(injector);
        this._data = this.searchTerms.pipe(
            debounceTime(this.delay),
            distinctUntilChanged(),
            switchMap(term => this.getObservable(this.searchValue))
        )
    }

    handleFilter(value) {
        this.value = value;
        this.searchTerms.next(value);
        this.onTouched();
    }

    handleValue(value) {
        this.manualChange();
        this.onTouched();
    }

    abstract getObservable(value:string):Observable<T[]>;
}
