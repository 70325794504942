export class MathUtils {
    /**
     * Will return the max in the array + 1, or 1 if max is less than 1 / inexistent
     * @param numbers : array of integers.
     */
    public static GetNextFrom1(numbers:number[]) {
        return Math.max(0, ...numbers) + 1
    }

    /**
     * Will return the number given as first argument if it is between a and b (included),
     * otherwise gives a or b (the nearest value from first argument)
     */
    public static clamp(num:number, a:number, b:number) {
        let min:number, max:number;
        if (a <= b) {
            [min, max] = [a, b];
        } else {
            [min, max] = [b, a];
        }
        return Math.min(Math.max(num, min), max);
    }
}
