import { Injector, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaCheckboxComponent } from '@fiba/forms/components/fiba-checkbox.component';
import { ItemCategoryCodes } from '@fiba/models';

@Component({
    selector: 'fibaCheckboxLabelItem',
    templateUrl: './fiba-checkbox-label-item.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaCheckboxLabelItemComponent),
            multi: true
        }
    ],
    host: { 'class': 'fiba-input' }
})
export class FibaCheckboxLabelItemComponent extends FibaCheckboxComponent {
    @Input() public itemCategoryCode: string = ItemCategoryCodes.CoreDisplayText;
    @Input() public itemCode: string;
    @Input() public text: string;

    constructor(protected injector: Injector) {
        super(injector);
    }
}
