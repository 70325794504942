import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputTextNumeric',
    templateUrl: './fiba-input-text-numeric.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputTextNumericComponent),
            multi: true
        }
    ],
    host: {'class': 'fiba-input'}
})
export class FibaInputTextNumericComponent extends FibaInputBase<number> {
    @Input() min:number;
    @Input() max:number = 2147483647;
    @Input() showButtons:boolean = false;
    @Input() format:string = '#';
    public autoCorrect:boolean = true;

    constructor(protected injector:Injector) {
        super(injector);
    }

    handleValue(value) {
        this.onChange(value);
        this.onTouched();
    }

    handleKeypress($event:KeyboardEvent) {
        // On Enter keypress, trigger blur, otherwise ngModel is not updated
        if ($event.which === 13) {
            (<HTMLElement>$event.target).blur();
        }
    }
}
