export class Automapper {
    /**
     * Copies properties from source to new object. Copies only properties defined in dest
     * @param type type of the object you want to build
     * @param source object to load from
     * @return the dest object
     */
    static autoMap<T>(dest:T, source:any):T {
        if (source) {
            for (let key in dest) {
                if (source.hasOwnProperty(key)) {
                    dest[key] = source[key];
                }
            }
        }
        return dest;
    }

    /**
     * Copies objects from source array to dest array
     * @param type type of the object you want to build
     * @param source source array
     * @return the dest array
     */
    static autoMapArray<T>(type:{ new(...args:any[]):T; }, source:any):Array<T> {
        let tmpArray = [];
        if (source && Array.isArray(source)) {
            for (let item of source) {
                tmpArray.push(new type(item));
            }
        }
        return tmpArray;
    }
}
