import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ItemDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { AppConfig } from '@app/app.config';

@Injectable()
export class ItemDataService {
    protected apiEndpoint: string;

    constructor(protected dataService: DataCachedService<ItemDTO>, protected config: AppConfig) {
        this.apiEndpoint = config.itemApiEndpoint;
    }

    fetchItems(categoryCode: string, parentCode?: string): Observable<ItemDTO[]> {
        return this.dataService.fetchEntities(this.apiEndpoint + '/' + categoryCode, categoryCode);
    }

    fetchItem(itemCategoryCode: string, itemCode?: string): Observable<ItemDTO> {
        return this.dataService.fetchEntity(this.apiEndpoint + '/' + itemCategoryCode + '/' + itemCode, itemCategoryCode + itemCode);
    }
}

