import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FibaInputBase } from '../base/fiba-input-base';

@Component({
    selector: 'fibaInputPassword',
    templateUrl: './fiba-input-password.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FibaInputPasswordComponent),
            multi: true
        }
    ],
    host: {'class': 'fiba-input'}
})
export class FibaInputPasswordComponent extends FibaInputBase<string> {
    @Input() protected fibaEqualValidator:string;

    constructor(protected injector:Injector) {
        super(injector);
    }

    onPasswordChanged($event):void {
        this.value = $event;
        this.manualChange();
    }
}
